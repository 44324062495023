import FileUpload from "../form/FileUpload";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import Filter from "./Filter";

export default function Toolbar({dataProvider, create, onRefresh, setFilters, filters, fields, onCreate, showAutoReload = false}) {
    const [refreshDuration, setRefreshDuration] = useState(0);
    const [autoRefresh, setAutoRefresh] = useState(undefined);

    const autoUpdate = function (refresh) {
        if (refresh !== "0") {
            setAutoRefresh(setInterval(
                () => {
                    onRefresh();
                }
                , refresh * 1000));
            setRefreshDuration(refresh);
        } else {
            if (autoRefresh) {
                clearInterval(autoRefresh);
            }
        }
    }

    onCreate = onCreate || (() => navigate("new"));

    const navigate = useNavigate();

    return (
        <div className="d-flex">
            {create &&
                <button className="btn btn-outline-primary me-2" onClick={onCreate}>
                    <i className="fa-solid fa-square-plus"/>
                </button>}
            <FileUpload uploadUrl={'/api/rest/admin/' + dataProvider.getEntity() + '/import2'}
                        filename="import.json" label={<i className="fa-solid fa-upload"/>} type="simple"/>
            <a className="btn btn-outline-primary me-2" href={"/api/rest/admin/" + dataProvider.getEntity() + "/export"}>
                <i className="fa-solid fa-download"/>
            </a>
            <Filter filters={filters} setFilters={setFilters} fields={fields}/>

            <div className="input-group me-2">
                <button className="btn btn-outline-primary" onClick={() => navigate(0)}>
                    <i className="fa-solid fa-arrow-rotate-right"/>
                </button>
                {showAutoReload &&
                    <select className="form-select border-primary" value={refreshDuration}
                            onChange={(e) => autoUpdate(e.target.value)}>
                        <option value={0}>Off</option>
                        <option value={1}>1 Second</option>
                        <option value={2}>2 Second</option>
                        <option value={5}>5 Seconds</option>
                        <option value={10}>10 Seconds</option>
                    </select>
                }
            </div>
        </div>
    )
}