import {useCallback, useEffect, useState} from "react";

export default function ({buttonClassName, buttonLabel, items = [], buttonStyle = {}, children}) {
    const [display, setDisplay] = useState();

    const handleClick = useCallback(() => {
        if (display) {
            setDisplay(false);
        }
    }, [display]);

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    });

    return (
        <div className="dropdown">
            <button className={buttonClassName} type="button" style={buttonStyle} onClick={() => setDisplay(true)}>
                {buttonLabel}
            </button>
            <div className={"dropdown-menu shadow border-0" + (display ? " show" : "")}>
                {
                    children ?
                        children
                        :
                        items.map(item =>
                            <div key={item.label} className="dropdown-item" onClick={item.onClick}>{item.label}</div>
                        )
                }
            </div>
        </div>
    )
}