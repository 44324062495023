import {getProperty} from "dot-prop";

export default function({column, references, item}) {

    if (column.reference) {
        const rs = references[column.id];
        const r = rs?.find(d => getProperty(d, column.reference.filter) === getProperty(item, column.reference.key))

        if (r) {
            return column.render(r) || "";
        }

        return "Loading data...";
    }

    if (column.render) {
        return column.render(item) || "";
    }

    return getProperty(item, column.id) || "";
}