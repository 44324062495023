import {useNavigate} from "react-router-dom";
import DataProvider from "../management-lib/util/DataProvider";
import Manage from "../management-lib/util/manage/Manage";
import RenderUtil from "../management-lib/util/RenderUtil";

export default function ({dataProvider = new DataProvider("share-list")}) {

    const navigate = useNavigate();

    return (
        <Manage create={true} title="Share Lists" dataProvider={dataProvider} columns={[
            {id: "name", render: i => i.name},
            {name: "Cases", render: i => i?.cases?.length || "0"},
            {id: "available", name: "Public", render: i => RenderUtil.boolean(i.available)},
            {name: "Link", render: i => <a href={"/dashboard/share/" + i.id}>Go!</a>},
        ]}
                onClick={(i) => navigate(i.id)}
        />
    )
}