import {Field} from "react-final-form";
import Label from "./Label";

const Input = function ({name, placeholder, label, undertext, showLabel = true, validate, rows = 3}) {
    return (
        <div className="mb-3">
            <Field name={name} validate={validate}>
                {({input, meta}) => (
                    <>
                        <Label label={label} showLabel={showLabel} name={name}/>
                        <textarea {...input} className={"form-control" + (meta.error && meta.touched? " is-invalid": "")} placeholder={placeholder} rows={rows}/>
                        {meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>}
                    </>
                )}
            </Field>
            {undertext && <div className="form-text">{undertext}</div>}
        </div>
    )
}

export default Input;