import {NavLink} from "react-router-dom";
import AccordionNavigation from "./AccordionNavigation";
import React from "react";

export default function ({displayMenu, setDisplayMenu, config}) {

    return (
        <div className={"navigation text-dark nav flex-column nav-pills me-3 pe-3 pt-3 min-vh-100 border-end border-1 d-md-block w-md-auto " + (displayMenu? "d-block": " d-none")}>
            <MenuRenderer config={config} setDisplayMenu={setDisplayMenu}/>
        </div>
    )
}

function MenuRenderer({config, setDisplayMenu}) {
    return config.map(i => {
        if (i.type === 'separator') {
            return <hr/>;
        } else if (i.type === 'item') {
            return (
                <NavLink key={i.label} className="nav-link" end to={i.location} onClick={() => setDisplayMenu(false)}>
                    {i.icon} <span className="ps-2">{i.label}</span>
                </NavLink>
            )
        } else if (i.type === 'menu') {
            return (
                <AccordionNavigation key={i.label} title={i.label} icon={i.icon}>
                    <MenuRenderer config={i.items} setDisplayMenu={setDisplayMenu}/>
                </AccordionNavigation>
            )
        }
    })

}