import {useParams} from "react-router-dom";
import DataProvider from "../management-lib/util/DataProvider";
import EntityEdit from "../management-lib/util/EntityEdit";
import Input from "../management-lib/util/form/Input";
import ValidationUtil from "../management-lib/util/ValidationUtil";

export default function ({dataProvider = new DataProvider("case-type")}) {

    let params = useParams();

    const id = params.id;

    return (
        <EntityEdit id={id} dataProvider={dataProvider} render={() =>
            <>
                <Input name="name" placeholder="name" label="Name" validate={ValidationUtil.required}/>
            </>
        }/>
    )
}