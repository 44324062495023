import React from "react";

export const MenuConfig = [
    {
        type: "item",
        label: "My Missions",
        location: "/dashboard",
        icon: <i className="fa-solid fa-address-card"/>
    },
    {
        type: "item",
        label: "Active Missions",
        location: "/dashboard/active-missions",
        icon: <i className="fa-solid fa-gauge"/>
    },
    {
        type: "item",
        label: "Cases",
        location: "/dashboard/cases",
        icon: <i className="fa-solid fa-folder"/>
    },
    {
        type: "item",
        label: "Individuals",
        location: "/dashboard/individuals",
        icon: <i className="fa-solid fa-child"/>
    },
    {
        type: "item",
        label: "Aid Records",
        location: "/dashboard/aid-records",
        icon: <i className="fa-solid fa-handshake-angle"/>
    },
    {
        type: "item",
        label: "Map",
        location: "/dashboard/map",
        icon: <i className="fa-solid fa-map"/>
    },
    {
        type: "item",
        label: "Teams",
        location: "/dashboard/teams",
        icon: <i className="fa-solid fa-people-group"/>
    },
    {
        type: "item",
        label: "Volunteers",
        location: "/dashboard/volunteers",
        icon: <i className="fa-solid fa-user"/>
    },
    {
        type: "item",
        label: "Missions",
        location: "/dashboard/missions",
        icon: <i className="fa-solid fa-person-walking-luggage"/>
    },
    {
        type: "item",
        label: "Extract Cases",
        location: "/dashboard/extract-cases",
        icon: <i className="fa-solid fa-expand"/>
    },
    {
        type: "item",
        label: "Share List",
        location: "/dashboard/share-lists",
        icon: <i className="fa-solid fa-share-from-square"/>
    },
    {
        type: "separator",
    },
    {
        type: "menu",
        label: "Config",
        icon: <i className="fa-solid fa-gears"/>,
        items: [
            {
                type: "item",
                label: "Case Types",
                location: "/dashboard/case-types",
                icon: <i className="fa-solid fa-children"/>
            },
            {
                type: "item",
                label: "Aid Types",
                location: "/dashboard/aid-types",
                icon: <i className="fa-solid fa-hand-holding-hand"/>
            },
            {
                type: "item",
                label: "Province",
                location: "/dashboard/province",
                icon: <i className="fa-solid fa-earth-oceania"/>
            }
        ]
    }
]