import {useState} from "react";
import RenderUtil from "../RenderUtil";
import Modal from "../Modal";
import DropDown from "../DropDown";

export default function Filter({filters, setFilters, fields}) {
    const [value, setValue] = useState();
    const [display, setDisplay] = useState(false);
    const [field, setField] = useState();

    const add = function () {
        const modified = [...filters];
        modified.push({name: field, value: value});
        setFilters(modified);
    }

    const remove = function (filter) {
        let modified = [...filters];
        modified = modified.filter(f => f.name !== filter.name);
        setFilters(modified);
    }

    return (
        <>
            <DropDown buttonLabel={<i className="fa-solid fa-filter"/>}
                      buttonClassName="h-100 btn btn-outline-primary me-2"
                      items={
                          fields.map(f => {
                              return {
                                  label: f.name || RenderUtil.toLabel(f.id), onClick: () => {
                                      setField(f.id);
                                      setDisplay(true);
                                  }
                              }
                          })
                      }/>
            {display && <Modal display={display} setDisplay={setDisplay} title="Filter">
                <div>
                    <div className="input-group mb-3">
                        <input autoFocus type="text" className="form-control" value={value}
                               onChange={(e => setValue(e.target.value))} onKeyPress={event => {
                            if (event.key === 'Enter') {
                                add();
                            }
                        }}/>
                        <button className="btn btn-primary btn-sm px-3 me-2" onClick={() => add()}>
                            <i className="fa-solid fa-magnifying-glass"/>
                        </button>

                    </div>
                </div>
            </Modal>
            }
        </>
    )
}