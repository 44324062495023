import {useParams} from "react-router-dom";
import Readonly from "../management-lib/util/form/Readonly";
import ChooseValue from "../management-lib/util/form/ChooseValue";
import DataProvider from "../management-lib/util/DataProvider";
import Input from "../management-lib/util/form/Input";
import EntityEdit from "../management-lib/util/EntityEdit";
import RenderUtil from "../management-lib/util/RenderUtil";

export default function ({dataProvider = new DataProvider("aid-record")}) {
    let params = useParams();

    const id = params.id;

    return (
        <EntityEdit id={id} dataProvider={dataProvider} render={() =>
            <>
                <Readonly name="timestamp" render={(i) => RenderUtil.date(i)}/>
                <ChooseValue showFreeSearch={true} dataProvider={new DataProvider("case")} columns={[
                    {id: "name", render: (i) => i.name}
                ]} objectToId={v => v.id} convertToAttribute={v => v} attributeToId={v => v.id} name="case" label="Case"
                             renderLabel={
                                 (d) => d.name
                             }
                />
                <ChooseValue dataProvider={new DataProvider("aid-type")} columns={[
                    {id: "name"}
                ]} objectToId={v => v.id} convertToAttribute={v => v} attributeToId={v => v.id} name="type"
                             renderLabel={
                                 (d) => d.name
                             }
                />
                <Input name="description"/>
            </>
        }/>
    )
}