import {Route, Routes} from "react-router-dom";
import MyMissions from "./MyMissions";
import CaseList from "../case/CaseList";
import TeamList from "../team/TeamList";
import VolunteerList from "../volunteer/VolunteerList";
import MissionList from "../mission/MissionList";
import CaseTypeList from "../caseType/CaseTypeList";
import AidTypeList from "../aidType/AidTypeList";
import ShareLists from "../shareList/ShareLists";
import VolunteerEdit from "../volunteer/VolunteerEdit";
import TeamEdit from "../team/TeamEdit";
import MissionEdit from "../mission/MissionEdit";
import CaseEdit from "../case/CaseEdit";
import CaseTypeEdit from "../caseType/CaseTypeEdit";
import AidTypeEdit from "../aidType/AidTypeEdit";
import AidRecordEdit from "../aidRecord/AidRecordEdit";
import ShareListEdit from "../shareList/ShareListEdit";
import ProvinceList from "../province/ProvinceList";
import ProvinceEdit from "../province/ProvinceEdit";
import Login from "../Login";
import React, {useState} from "react";
import NotFound from "../layout/NotFound";
import MissionView from "./MissionView";
import Map from "../map/Map";
import ActiveMissions from "./ActiveMissions";
import Navigation from "../management-lib/layout/navigation/Navigation";
import {MenuConfig} from "../layout/MenuConfig";
import AidRecordList from "../aidRecord/AidRecordList";
import IndividualList from "../individual/IndividualList";
import IndividualEdit from "../individual/IndividualEdit";
import ExtractCases from "../mission/ExtractCases";

export default function ({displayMenu, setDisplayMenu}) {
    return (
        <>
            <div className="p-3 h-100">
                <div className="d-flex align-items-start h-100">
                    <Navigation displayMenu={displayMenu} setDisplayMenu={setDisplayMenu} config={MenuConfig}/>

                    <div className="tab-content flex-grow-1">
                        <div className="tab-pane fade show active">
                            <Routes>
                                <Route index element={<MyMissions/>}/>
                                <Route path="cases" element={<CaseList/>}/>
                                <Route path="individuals" element={<IndividualList/>}/>
                                <Route path="individuals/:id" element={<IndividualEdit/>}/>
                                <Route path="map" element={<Map/>}/>
                                <Route path="teams" element={<TeamList/>}/>
                                <Route path="volunteers" element={<VolunteerList/>}/>
                                <Route path="missions" element={<MissionList/>}/>
                                <Route path="active-missions" element={<ActiveMissions/>}/>
                                <Route path="case-types" element={<CaseTypeList/>}/>
                                <Route path="aid-types" element={<AidTypeList/>}/>
                                <Route path="share-lists" element={<ShareLists/>}/>
                                <Route path="province" element={<ProvinceList/>}/>
                                <Route path="aid-records" element={<AidRecordList displayName={true}/>}/>

                                <Route path="volunteers/:id/*" element={<VolunteerEdit/>}/>
                                <Route path="teams/:id/*" element={<TeamEdit/>}/>
                                <Route path="missions/:id/*" element={<MissionEdit/>}/>
                                <Route path="cases/:id/*" element={<CaseEdit/>}/>
                                <Route path="case-types/:id/*" element={<CaseTypeEdit/>}/>
                                <Route path="aid-types/:id/*" element={<AidTypeEdit/>}/>
                                <Route path="aid-records/:id/*" element={<AidRecordEdit/>}/>
                                <Route path="share-lists/:id/*" element={<ShareListEdit/>}/>
                                <Route path="mission-view/:id/*" element={<MissionView/>}/>
                                <Route path="province/:id/*" element={<ProvinceEdit/>}/>
                                <Route path="extract-cases/*" element={<ExtractCases/>}/>
                                <Route path="login" element={<Login/>}/>
                                <Route path='*' element={<NotFound/>}/>
                            </Routes>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}