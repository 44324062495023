import {FieldArray} from "react-final-form-arrays";

export default function ({name, render, newValue = {}, allowMovement = true}) {

    const canMoveUp = function(index) {
        return index > 0;
    }

    const canMoveDown = function(index, length) {
        return index < length -1;
    }

    return (
        <FieldArray name={name} render=
            {({fields}) => (
                <div className="pb-4 mt-2">
                    {fields.map((name, index) => (
                        <div key={name} className="array-element rounded-3 border border-1 p-3 mb-3">
                            <div className="d-flex justify-content-between w-100">
                                <div>
                                    {fields.length > 1 && <strong className="border p-1 px-2 rounded bg-light">{index + 1}</strong>}
                                </div>
                                <div>
                                    {allowMovement && canMoveUp(index) &&
                                    <button type="button" className="btn btn-primary btn-sm me-1"
                                            onClick={() => fields.move(index, index - 1)}>
                                        <i className="fa-solid fa-arrow-up"/>
                                    </button>
                                    }
                                    {allowMovement && canMoveDown(index, fields.length) &&
                                    <button type="button" className="btn btn-primary btn-sm me-1"
                                            onClick={() => fields.move(index, index + 1)}>
                                        <i className="fa-solid fa-arrow-down"/>
                                    </button>
                                    }
                                    <button type="button" className="btn btn-danger btn-sm "
                                            onClick={() => fields.remove(index)}>
                                        <i className="fa-solid fa-trash-can"/>
                                    </button>
                                </div>
                            </div>
                            {render({name, fields})}
                        </div>
                    ))}
                    <div>
                        <button
                            type="button" className="btn btn-primary btn-sm"
                            onClick={() => fields.push(newValue)}
                        >
                            <i className="fa-solid fa-square-plus"/>
                        </button>
                    </div>
                </div>
            )}
        />
    )
}
