import {Field} from "react-final-form";
import {useState} from "react";
import RenderUtil from "../../management-lib/util/RenderUtil";
import ValidationUtil from "../../management-lib/util/ValidationUtil";
import LocationUtil from "../LocationUtil";

const Input = function ({name, placeholder, label, undertext, component='input', showLabel = true}) {
    const [accuracy, setAccuracy] = useState(0);
    const [progressText, setProgressText] = useState(undertext);
    const [loading, setLoading] = useState();

    const getLocation = function (onChange) {
        if (navigator.geolocation) {
            setLoading(true);
            const geoId = navigator.geolocation.watchPosition(
                (position) => {
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;
                    onChange(lat + "," + lng);
                    setProgressText("Accurate to " + position.coords.accuracy+ " meters");
                    setAccuracy(position.coords.accuracy);
                    console.log({lat, lng}, position.coords.accuracy);
                    if (position.coords.accuracy < 10) {
                        window.navigator.geolocation.clearWatch(geoId);
                    }
                    setLoading(false);
                },
                (e) => {
                    setProgressText(e.message);
                    setAccuracy(100);
                    setLoading(false);
                },
                {enableHighAccuracy: true, maximumAge: 2000, timeout: 6000}
            );
            return () => {
                console.log('Clear watch called');
                window.navigator.geolocation.clearWatch(geoId);
            };
        }

        // navigator.geolocation.getCurrentPosition((d) => {
        //     console.log(d);
        //     onChange(d.coords.longitude + "," + d.coords.latitude);
        // });
    }

    const progressColor = accuracy < 10? "text-success": accuracy > 20? "text-danger": "text-warning";

    function validateLocation(v) {
        return LocationUtil.isValidLocation(v) ? undefined: "Invalid coordinates"
    }

    return (
        <div className="mb-3">
            {showLabel && <label className="form-label">{label? label: RenderUtil.toLabel(name)}</label>}
            <div className="input-group">
                <Field name={name} component={component} validate={validateLocation}>
                    {({input, meta}) => (
                        <>
                            <input {...input} className={"form-control" + (meta.error && meta.touched? " is-invalid": "")} placeholder={placeholder}/>
                            <span className={"btn btn-success" + (loading? " disabled": "")} onClick={() => getLocation(input.onChange)}>
                                {loading?
                                    <div className="spinner-border spinner-border-sm" role="status"/>
                                    :
                                    <i className="fa-solid fa-location-crosshairs"/>
                                }
                            </span>
                            {meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>}
                        </>
                    )}
                </Field>
            </div>
            {progressText && <div className={"form-text " + progressColor}>{progressText}</div>}
        </div>
    )
}

export default Input;