export default function Header({displayMenu, setDisplayMenu, children}) {
    return (
        <header className="p-3 bg-dark text-white shadow-sm">
            <div className="d-flex flex-wrap align-items-center justify-content-start justify-content-lg-start">
                <span className="h4 p-0 m-0 me-3 d-block d-md-none" onClick={() => setDisplayMenu(!displayMenu)}>
                    <i className="fa-solid fa-bars"/>
                </span>
                {children}
            </div>
        </header>
    )
}