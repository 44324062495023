import {useEffect, useState} from "react";
import {NavLink, Route, Routes, useParams} from "react-router-dom";

import MissionUtil from "../util/MissionUtil";
import CaseView from "./CaseView";
import MissionMapView from "./MissionMapView";
import DataProvider from "../management-lib/util/DataProvider";

export default function () {
    const [mission, setMission] = useState({});
    const dataProvider = new DataProvider("mission");
    const [loading, setLoading] = useState(true);
    const [provinces, setProvinces] = useState([]);
    const [provinceList, setProvinceList] = useState([]);

    let params = useParams();

    const id = params.id;

    const update = function (missionId, caseId, progress) {
        setLoading(true);
        if (progress === "New") {
            progress = null;
        }
        dataProvider.custom("update", "POST", {
            missionId: missionId,
            caseId: caseId,
            progress: progress
        }).then(data => {
            setMission(MissionUtil.sortMission(data));
            setLoading(false);
        })
    }

    const updateProvince = function (province, action) {
        let modified = [...provinces];

        modified = modified.filter(p => p.id !== province.id);

        if (action) {
            modified.push(province);
        }

        console.log(modified);

        setProvinces(modified);
    }

    const load = function () {
        setLoading(true);
        dataProvider.id(id).then(data => {
            console.log(data);
            setMission(MissionUtil.sortMission(data));

            const list = [];

            data.cases?.filter(c => c?.case?.location?.province).forEach(c2 => {
                console.log(c2);
                const found = list.find(i => i.id === c2.case.location.province.id);
                if (!found) {
                    list.push(c2?.case?.location?.province);
                } else {
                    found.count = (found.count || 1) + 1;
                }
            });

            setProvinceList(list);

            setLoading(false);
        });
    }

    function activeProvince(province) {
        return provinces.find(p => p.id === province?.id);
    }

    function included(province) {
        if (provinces.length === 0) {
            return true;
        }
        return provinces.find(p => p.id === province?.id);
    }

    useEffect(() => {
        load();
    }, []);

    return (
        <>
            <h5 className="mb-4">{mission?.name}</h5>
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <NavLink className="nav-link d-inline-block" end
                             to="">Cases</NavLink>
                    <NavLink className="nav-link d-inline-block"
                             to="map">Map</NavLink>
                </li>
            </ul>
            <div className="tab-content p-2">
                <div className="tab-pane fade show active h-100">
                    <Routes>
                        <Route index
                               element={
                                   <>
                                       {provinceList && provinceList.length > 1 &&
                                       <div className="mt-2 col-md-auto d-flex flex-row-reverse">
                                           {provinceList.map(p =>
                                               <span
                                                   className={"btn btn-sm me-2 " + (activeProvince(p) ? "btn-primary" : "btn-outline-primary")}
                                                   onClick={() => updateProvince(p, !activeProvince(p))}>{p.name} ({p.count})</span>
                                           )}
                                           <span
                                               className={"btn btn-sm me-2 " + (provinces.length === 0 ? "btn-primary" : "btn-outline-primary")}
                                               onClick={() => setProvinces([])}>All ({mission?.cases?.length})</span>
                                       </div>
                                       }
                                       {mission?.cases?.filter(c2 => included(c2.case?.location?.province)).map(c =>
                                           <CaseView mission={mission} c={c} update={update}/>
                                       )}
                                   </>
                               }/>
                        <Route path="map" element={<MissionMapView mission={mission}/>}/>
                    </Routes>
                </div>
            </div>
        </>
    )
}