import Select from "react-select";
import SelectCreateble from "react-select/creatable";
import {Field} from "react-final-form";
import Label from "./Label";

const MultiSelect = function ({name, options, convertToValue, convertToOption, label, allowCreate, showLabel = true}) {

    const update = function (onChange, value) {
        onChange(value?.map(v => convertToValue(v)));
    }

    const getValues = function (values) {
        const result = [];
        if (!values) {
            return result;
        }

        values?.forEach(t => {
            result.push(convertToOption(t));
        });

        return result;
    }

    return (
        <div className="mb-3">
            <Label label={label} showLabel={showLabel} name={name}/>
            <Field name={name} type="text">
                {({input}) =>
                    allowCreate ?
                        <SelectCreateble isMulti value={getValues(input.value)} onChange={(v) => update(input.onChange, v)}
                                         options={getValues(options)}/>
                        :
                        <Select isMulti value={getValues(input.value)} onChange={(v) => update(input.onChange, v)}
                                options={getValues(options)}/>
                }
            </Field>
        </div>
    )
}

export default MultiSelect;