export default function () {
    return (
        <main className="flex-shrink-0">
            <div className="container">
                <div className="card p-5 mt-5">
                    <h1 className="">Login</h1>
                    <p className="lead">You don't seem to have access, please login, <a
                        href="/api/session/login/google/start">click here</a></p>
                </div>
            </div>
        </main>
    )
}