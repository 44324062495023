class DataProvider {

    constructor(entity, filters = [], label) {
        this.entity = entity;
        this.filters = filters;
        this.label = label;
        this.url = "/api/admin/" + entity;
    }

    getEntity() {
        return this.entity;
    }

    getLabel() {
        return this.label;
    }

    setFilters(filters) {
        this.filters = filters;
    }

    get(start = 0, end = 25, order, sort, filters = []) {
        let url = this.url + "?_start=" + start + "&_end=" + end;

        if (order && sort) {
            url += "&_order=" + order + "&_sort=" + sort;
        }

        if (this.filters) {
            this.filters.map(f => {
                url += "&" + f.name + "=" + f.value;
            })
        }

        if (filters) {
            filters.map(f => {
                url += "&" + f.name + "=" + f.value;
            })
        }

        return fetch(url).then(response => {
            if (response.status === 401 || response.status === 403) {
                window.location.href = "/";
            }

            return response.json().then(json => ({
                total: response.headers.get("X-Total-Count"),
                data: json
            }));
        });
    }

    text(start = 0, end = 25, order, sort, text) {
        let url = this.url + "/text?_start=" + start + "&_end=" + end;

        if (order && sort) {
            url += "&_order=" + order + "&_sort=" + sort;
        }

        if (text) {
            url += "&text=" + text;
        }

        return fetch(url).then(response => {
            return response.json().then(json => ({
                total: response.headers.get("X-Total-Count"),
                data: json
            }));
        });
    }

    count(filters = []) {
        let url = this.url + "/count";

        if (this.filters) {
            this.filters.map(f => {
                url += "&" + f.name + "=" + f.value;
            })
        }

        if (filters) {
            filters.map(f => {
                url += "&" + f.name + "=" + f.value;
            })
        }

        return fetch(url).then(response => {
            return response.json();
        });
    }

    id(id) {
        let url = this.url + "/" + id;
        return fetch(url).then(response => response.json());
    }

    ids(ids) {
        let url = this.url + "?id=" + ids.join("&id=");
        return fetch(url).then(response => response.json());
    }

    save(data) {
        let url = this.url + "/" + data.id;

        return this.checkResponse(fetch(url, {
            method: 'PUT', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        }));
    }

    create(data) {
        let url = this.url;

        return this.checkResponse(fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        }));
    }

    delete(id) {
        let url = this.url + "/" + id;

        return this.checkResponse(fetch(url, {
            method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        }));
    }

    custom(action, method, data) {
        let url = this.url + "/" + action;

        const options = {
            method: method, // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        };

        if (data) {
            options.body = JSON.stringify(data);
        }

        return this.checkResponse(fetch(url, options));
    }

    checkResponse(promise) {
        return promise.then(response => {
            if (response.status === 401 || response.status === 403) {
                window.location.href = "/";
            }

            if (!response.ok) {
                // create error object and reject if not a 2xx response code
                let err = new Error("HTTP status code: " + response.status)
                err.response = response
                err.status = response.status
                throw err
            }
            return response.json();
        });
    }
}

export default DataProvider;