import RenderUtil from "../../RenderUtil";

export default function({columns, setSort, setOrder, order, sort}) {
    return (
        columns.map(c =>
            <th key={c.id || c.name} onClick={c.id && (() => {
                setSort(c.id);
                setOrder(order === "ASC" ? "DESC" : "ASC")
            })}>{c.id && sort === c.id ? order === "ASC" ?
                <i className="fas fa-sort-amount-down-alt me-1"/>
                :
                <i className="fas fa-sort-amount-down me-1"/> : ""
            }{c.name ? c.name : RenderUtil.toLabel(c.id)}</th>
        )
    )
}