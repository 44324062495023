export default function({values}) {

    return (
        values?.map(v => {
            if (v.type === "MOBILE" || v.type === "PHONE") {
                return <a className="btn btn-primary me-2" href={"tel:" + v.value}><i className="fa-solid fa-mobile-retro"/> {v.value}</a>
            } else if (v.type === "EMAIL") {
                <a className="btn btn-primary me-2" href={"phone:" + v.value}><i className="fa-solid fa-envelope"/> {v.value}</a>
            } else {
                return <span className="me-2 badge bg-primary">{v.value}</span>
            }
        })
    )
}