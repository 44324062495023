import {NavLink, Route, Routes, useParams} from "react-router-dom";

import Contacts from "../util/Contacts";
import Location from "../util/Location";
import {useEffect, useState} from "react";
import AidRecordList from "../aidRecord/AidRecordList";
import TextArea from "../management-lib/util/form/TextArea";
import EntityEdit from "../management-lib/util/EntityEdit";
import MultiSelect from "../management-lib/util/form/MultiSelect";
import Input from "../management-lib/util/form/Input";
import Checkbox from "../management-lib/util/form/Checkbox";
import DataProvider from "../management-lib/util/DataProvider";
import ValidationUtil from "../management-lib/util/ValidationUtil";
import Select from "../management-lib/util/form/Select";
import FileUpload from "../management-lib/util/form/FileUpload";
import IndividualList from "../individual/IndividualList";
import MissionList from "../mission/MissionList";

export default function ({dataProvider = new DataProvider("case")}) {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const dataProvider = new DataProvider("case-type");
        dataProvider.get().then(response => {
            setOptions(response.data);
        });

    }, []);

    let params = useParams();

    const id = params.id;

    return (
        <EntityEdit id={id} dataProvider={dataProvider} render={({values}) =>
            <div>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <NavLink className="nav-link d-inline-block" end
                                 to="">Basic</NavLink>
                        <NavLink className="nav-link d-inline-block"
                                 to="contact-information">Contact Information</NavLink>
                        <NavLink className="nav-link d-inline-block"
                                 to="location">Location</NavLink>
                        <NavLink className="nav-link d-inline-block"
                                 to="aid-records">Aid Records</NavLink>
                        <NavLink className="nav-link d-inline-block"
                                 to="individuals">Individuals</NavLink>
                        <NavLink className="nav-link d-inline-block"
                                 to="documents">Documents</NavLink>
                        <NavLink className="nav-link d-inline-block"
                                 to="missions">Missions</NavLink>
                    </li>
                </ul>
                <div className="tab-content p-2">
                    <div className="tab-pane fade show active h-100">
                        <Routes>
                            <Route index element={
                                <>
                                    <Input name="name" placeholder="name" label="Name" validate={ValidationUtil.required}/>
                                    <MultiSelect name="types" options={options}
                                                 convertToValue={(v) => options.find(o => o.id === v.value)}
                                                 convertToOption={(v) => {
                                                     return {value: v.id, label: v.name}
                                                 }}/>
                                    <Select name="status" label="status"
                                            options={[
                                                {value: "NEW", text: "New"},
                                                {value: "UNDERINVESTIGATION", text: "Under Investigation"},
                                                {value: "REJECTED", text: "Rejected"},
                                                {value: "VERIFIED", text: "Verified"},
                                            ]}/>
                                    <TextArea name="description" placeholder="description" label="Description"/>
                                </>
                            }/>
                            <Route path="contact-information" element={
                                <Contacts name="contacts"/>
                            }/>
                            <Route path="location" element={
                                <Location name="location"/>
                            }/>
                            <Route path="documents" element={
                                <Files dataProvider={dataProvider} id={values.id}/>
                            }/>
                            <Route path="missions" element={
                                <MissionList dataProvider={new DataProvider("mission", [
                                    {name: "cases.caseRef.id", value: values?.id}
                                ])}
                                />
                            }/>
                            <Route path="individuals" element={
                                <IndividualList dataProvider={new DataProvider("individual", [
                                    {name: "caseId", value: values?.id}
                                ])}/>
                            }/>
                            <Route path="aid-records" element={
                                <AidRecordList dataProvider={new DataProvider("aid-record", [{
                                    name: "caseRef.id",
                                    value: id
                                }])}/>
                            }/>
                        </Routes>
                    </div>
                </div>
            </div>
        }/>
    )
}

const Files = function ({dataProvider, id}) {
    const [files, setFiles] = useState([]);

    function load() {
        dataProvider.custom("file/list/" + id).then(files => {
            setFiles(files);
        })
    }

    function deleteFile(filename) {
        dataProvider.custom("file/delete/" + id + "?filename=" + encodeURIComponent(filename), 'DELETE').then((t) => {
            console.log("test");
            load();
        });
    }

    useEffect(() => {
        load();
    }, [id])

    return (
        <>
            <div className="d-flex flex-row-reverse mt-2">
                <FileUpload uploadUrl={'/api/admin/' + dataProvider.getEntity() + '/file/upload/' + id}
                            filename="import.json" label={<><i className="fa-solid fa-upload"/> Upload</>} type="simple" onComplete={() => load()}/>
            </div>
            <table className="table table-hover">
                <thead>
                <tr>
                    <th>Filename</th>
                    <th>Size</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {files.map(f =>
                    <tr>
                        <td><a href={"/api/admin/" + dataProvider.getEntity() + "/file/download/" + id + "?filename=" + encodeURIComponent(f.name)}>{f.name}</a></td>
                        <td>{f.size?.toLocaleString()}</td>
                        <td>
                            <button type="button" className="btn btn-danger btn-sm "
                                    onClick={() => deleteFile(f.name)}>
                            <i className="fa-solid fa-trash-can"/>
                        </button>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </>
    )
}