import {Marker, Popup} from "react-leaflet";
import {Link} from "react-router-dom";
import {MapContainer} from 'react-leaflet/MapContainer'
import {TileLayer} from 'react-leaflet/TileLayer'
import React from "react";
import L from 'leaflet';
import LocationUtil from "../util/LocationUtil";

export default function MissionMapView({mission}) {

    function createIcon(c) {
        if (c.progress === 'DONE') {
            return createDoneIcon();
        } else if (c.progress === 'INPROGRESS') {
            return createProgressIcon();
        } else if (c.progress === 'PENDING') {
            return createPendingIcon();
        }

        return createNormalIcon();
    }

    function createNormalIcon() {
        return new L.Icon({
            iconUrl: "/marker-icon.png",
            shadowUrl: "/shadow-icon.png",
        })
    }

    function createPendingIcon() {
        return new L.Icon({
            iconUrl: "/marker-icon-grey.png",
            shadowUrl: "/shadow-icon.png",
        })
    }

    function createProgressIcon() {
        return new L.Icon({
            iconUrl: "/marker-icon-gold.png",
            shadowUrl: "/shadow-icon.png",
        })
    }

    function createDoneIcon() {
        return new L.Icon({
            iconUrl: "/marker-icon-green.png",
            shadowUrl: "/shadow-icon.png",
        })
    }

    return (
        <>
            {mission && <Warning mission={mission}/>}
            <div className="border rounded mw-100 mt-3" style={{height: 800}}>
                <MapContainer center={[31.95373661990264, 35.90918589389421]} zoom={12} scrollWheelZoom={true}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    {mission?.cases?.filter(cp => LocationUtil.isValidLocation(cp.case?.location?.coordinates)).map(c =>
                        <Marker position={LocationUtil.getDecimalCoordinates(c.case?.location?.coordinates)?.split(",")} icon={createIcon(c)}>
                            <Popup>
                                <Link className="text-reset text-decoration-none" to={"/dashboard/cases/" + c.case.id}><i
                                    className="fa-solid fa-folder"/> {c.case?.name}</Link>
                                {c.case?.location?.province &&
                                    <div className="mt-3">
                                        <i className="fa-solid fa-earth-oceania"/> {c.case?.location?.province.name}
                                    </div>
                                }
                                {c.case?.location?.description &&
                                    <div className="mt-3">
                                        <i className="fa-solid fa-circle-info"/> {c.case?.location?.description}
                                    </div>
                                }
                                <div className="mt-3">
                                    <a className="text-reset text-decoration-none"
                                       href={"https://www.google.com/maps/place/" + LocationUtil.getDecimalCoordinates(c.case?.location?.coordinates)}>
                                        <i className="fa-solid fa-map-location-dot"/> Directions
                                    </a>
                                </div>
                                {c?.case?.contacts &&
                                    <Contacts values={c.case.contacts}/>}
                                {c?.supplies?.length > 0 &&
                                    <>
                                        <hr/>
                                        <div>
                                            {c?.supplies?.map(s =>
                                                <div className="d-flex justify-content-between">
                                                    <div>{s?.aidType?.name}</div>
                                                    <div><span className="badge bg-primary">{s?.amount}</span></div>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                }
                            </Popup>
                        </Marker>
                    )}
                </MapContainer>
            </div>
        </>
    )
}

function Contacts({values}) {

    return (
        values?.map(v => {
            if (v.type === "MOBILE" || v.type === "PHONE") {
                return (
                    <div className="mt-3">
                        <a className="text-reset text-decoration-none mt-3" href={"tel:" + v.value}><i className="fa-solid fa-mobile-retro me-1"/> {v.value}</a>
                    </div>
                )
            } else if (v.type === "EMAIL") {
                return (
                    <div className="mt-3">
                        <a className="text-reset text-decoration-none mt-3" href={"email:" + v.value}><i className="fa-solid fa-envelope"/> {v.value}</a>
                    </div>
                )
            } else {
                return <span className="me-2 badge bg-primary">{v.value}</span>
            }
        })
    )
}

function Warning({mission}) {
    const [more, setMore] = React.useState(false);

    const cases = mission.cases;

    const warnings = [];

    cases?.forEach(c => {
        if (!c.case?.location?.coordinates) {
            warnings.push({case: c.case, warning: `Case ${c?.case?.name} doesn't have a location`});
        } else if (!LocationUtil.isValidLocation(c.case?.location?.coordinates)) {
            warnings.push({case: c.case, warning: `Case ${c?.case?.name} doesn't have valid coordinates`});
        }
    });

    if (warnings.length === 1) {
        return <></>
    }

    return (
        <div className="alert alert-danger">
            <ul className="m-0">
                {warnings?.map((c, index) =>
                    <li className={index < 5 || more ? '' : 'd-none'}><Link className="text-decoration-none text-reset"
                                                                            to={"/dashboard/cases/" + c.case?.id}> {c?.warning}</Link></li>
                )}
            </ul>
            {warnings?.length > 5 && <button className="btn-link btn mt-2" onClick={() => setMore(!more)}>{more ? 'Less' : 'More'}</button>}
        </div>
    )
}