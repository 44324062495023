import Header from "./Header";
import Data from "./Data";

export default function ({columns, order, setOrder, setSort, sort, data, onClick, references}) {
    return (
        <table className="table table-hover">
            <thead>
            <tr>
                <Header columns={columns} order={order} setOrder={setOrder} setSort={setSort} sort={sort}/>
            </tr>
            </thead>
            <tbody>
            <Data data={data} onClick={onClick} columns={columns} references={references}/>
            </tbody>
        </table>
    )
}