import {useState} from "react";
import Modal from "../Modal";
import Manage from "../manage/Manage";

export default function ({
                             dataProvider,
                             columns,
                             onChange,
                         }) {
    const [display, setDisplay] = useState(false);

    const update = function (value) {
        dataProvider.id(value.id).then(d => {
            if (onChange) {
                onChange(d);
            }
            setDisplay(false);
        });
    }

    return (
        <div className="mb-3">
            <div className="input-group mb-3">
                <a className="btn btn-sm btn-primary" onClick={() => setDisplay(true)}>
                    <i className="fa-solid fa-square-plus"/>
                </a>
                {display &&
                    <Modal display={display} setDisplay={setDisplay} size="xl">
                        <Manage dataProvider={dataProvider} columns={columns} onClick={update}/>
                    </Modal>
                }
            </div>
        </div>
    )
}