import {Marker, Popup} from "react-leaflet";
import {Link} from "react-router-dom";
import {MapContainer} from 'react-leaflet/MapContainer'
import {TileLayer} from 'react-leaflet/TileLayer'
import React from "react";
import L from 'leaflet';
import LocationUtil from "../util/LocationUtil";

export default function ({cases}) {

    function createNormalIcon() {
        return new L.Icon({
            iconUrl: "/marker-icon.png",
            shadowUrl: "/shadow-icon.png",
        })
    }

    function validPosition(position) {
        if (!position) {
            return false;
        }

        const tokens = position.split(",");

        return tokens.length === 2;


    }

    return (
        <>
            <Warning cases={cases}/>
            <div className="border rounded mw-100 mt-3" style={{height: 800}}>
                <MapContainer center={[31.95373661990264, 35.90918589389421]} zoom={12} scrollWheelZoom={true}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {cases?.filter(c => LocationUtil.isValidLocation(c?.location?.coordinates)).map(c =>
                        <Marker position={LocationUtil.getDecimalCoordinates(c?.location?.coordinates).split(",")} icon={createNormalIcon()}>
                            <Popup>
                                <Link className="text-reset text-decoration-none" to={"/dashboard/cases/" + c.id}><i
                                    className="fa-solid fa-folder"/> {c?.name}</Link>
                                {c?.location?.province &&
                                    <div className="mt-3">
                                        <i className="fa-solid fa-earth-oceania"/> {c?.location?.province.name}
                                    </div>
                                }
                                {c?.location?.description &&
                                    <div className="mt-3">
                                        <i className="fa-solid fa-circle-info"/> {c?.location?.description}
                                    </div>
                                }
                                <div className="mt-3">
                                    <a className="text-reset text-decoration-none"
                                       href={"https://www.google.com/maps/place/" + LocationUtil.getDecimalCoordinates(c?.location?.coordinates)}>
                                        <i className="fa-solid fa-map-location-dot"/> Directions
                                    </a>
                                </div>
                            </Popup>
                        </Marker>
                    )}
                </MapContainer>
            </div>
        </>
    )
}

function Warning({cases}) {
    const [more, setMore] = React.useState(false);

    const warnings = [];

    cases?.forEach(c => {
        if (!c?.location?.coordinates) {
            warnings.push({case: c, warning: `Case ${c?.name} doesn't have a location`});
        } else if (!LocationUtil.isValidLocation(c?.location?.coordinates)) {
            warnings.push({case: c, warning: `Case ${c?.name} doesn't have valid coordinates`});
        }
    });

    if (warnings.length === 1) {
        return <></>
    }

    return (
        <div className="alert alert-danger">
            <ul className="m-0">
                {warnings?.map((c, index) =>
                    <li className={index < 5 || more ? '' : 'd-none'}><Link className="text-decoration-none text-reset"
                                                                            to={"/dashboard/cases/" + c?.id}> {c?.warning}</Link></li>
                )}
            </ul>
            {warnings?.length > 5 && <button className="btn-link btn mt-2" onClick={() => setMore(!more)}>{more ? 'Less' : 'More'}</button>}
        </div>
    )
}