import {FieldArray} from "react-final-form-arrays";

export default function ({name, render, newValue = {}, allowMovement = false, columns, addLabel}) {

    const canMoveUp = function (index) {
        return index > 0;
    }

    const canMoveDown = function (index, length) {
        return index < length - 1;
    }

    return (
        <FieldArray name={name} render=
            {({fields}) => (
                <div>
                    {fields.length > 0 &&
                    <table className="table table-hover">
                        <thead>
                        <tr>
                            <th></th>
                            {columns.map(c =>
                                <th>{c}</th>
                            )}
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {fields.map((name, index) => (
                            <tr key={name} className="">
                                <td>{index + 1}</td>
                                {render({name, fields})}
                                <td>
                                    {allowMovement && canMoveUp(index) &&
                                    <button type="button" className="btn btn-primary btn-sm me-1"
                                            onClick={() => fields.move(index, index - 1)}>
                                        <i className="fa-solid fa-arrow-up"/>
                                    </button>
                                    }
                                    {allowMovement && canMoveDown(index, fields.length) &&
                                    <button type="button" className="btn btn-primary btn-sm me-1"
                                            onClick={() => fields.move(index, index + 1)}>
                                        <i className="fa-solid fa-arrow-down"/>
                                    </button>
                                    }
                                    <button type="button" className="btn btn-danger btn-sm "
                                            onClick={() => fields.remove(index)}>
                                        <i className="fa-solid fa-trash-can"/>
                                    </button>
                                </td>
                            </tr>
                        ))
                        }
                        </tbody>
                    </table>
                    }
                    <div>
                        <button
                            type="button" className="btn btn-primary btn-sm"
                            onClick={() => fields.push(newValue)}
                        >
                            {addLabel ||
                                <i className="fa-solid fa-square-plus"/>
                            }
                        </button>
                    </div>
                </div>
            )}
        />
    )
}
