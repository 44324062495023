import Pagination from "react-responsive-pagination";
import PageSize from "./PageSize";

export default function ({pages, page, setPage, pageSize, setPageSize, total}) {

    const from = page * pageSize + 1;
    let to = (page + 1) * pageSize;

    if (to > total) {
        to = total;
    }

    return (
        <div className="d-flex flex-row-reverse align-items-center">
            {pages > 1 &&
                <Pagination current={page + 1} total={pages}
                        onPageChange={(i) => setPage(i - 1)} maxWidth={600} extraClassName="p-0 m-0"/>
            }
            <span className="m-3 d-none d-md-block">{from}-{to} of {total}</span>
            <PageSize pageSize={pageSize} setPageSize={setPageSize}/>
            <span className="me-2 d-none d-md-block">
                Items per page
            </span>
        </div>
    )
}