import {useEffect, useState} from "react";
import MapView from "./MapView";
import DataProvider from "../management-lib/util/DataProvider";

export default function() {
    const [cases, setCases] = useState();

    useEffect(() => {
        new DataProvider("case").get(0, 1000).then(response => {
            setCases(response.data);
        });
    }, [])

    return (
        <MapView cases={cases}/>
    )
}