import React, {useState} from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/dashboard/Dashboard";
import ShareListView from "./components/shareList/ShareListView";
import NotFound from "./components/layout/NotFound";
import Header from "./components/layout/Header";

function App() {
    const [displayMenu, setDisplayMenu] = useState();

    return (
        <BrowserRouter>
            <Header displayMenu={displayMenu} setDisplayMenu={setDisplayMenu}>
                <span className="fw-bold h5 p-0 m-0">
                    <img src="/logo64.png" height="32" className="me-1"/> E7san
                </span>
            </Header>
            <Routes>
                <Route index element={<Login/>}/>
                <Route path="share/:id" element={<ShareListView/>}/>
                <Route path="dashboard/*" element={<Dashboard displayMenu={displayMenu} setDisplayMenu={setDisplayMenu}/>}/>
                <Route path='*' element={<NotFound/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
