import LocationInput from "./form/LocationInput";
import TextArea from "../management-lib/util/form/TextArea";
import ChooseValue from "../management-lib/util/form/ChooseValue";
import DataProvider from "../management-lib/util/DataProvider";
import Input from "../management-lib/util/form/Input";

export default function ({name}) {
    return (
        <>
            <ChooseValue dataProvider={new DataProvider("province")} columns={[
                {id: "name", render: (i) => i.name}
            ]} objectToId={v => v.id} convertToAttribute={v => v} attributeToId={v => v.id} name={`${name}.province`} label="Province"
                         renderLabel={
                             (d) => d.name
                         }
            />
            <Input name={`${name}.url`} label="URL"/>
            <LocationInput name={`${name}.coordinates`} label="Coordinates"/>
            <TextArea name={`${name}.description`} label="Description"/>
        </>
    )
}