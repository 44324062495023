import {useState} from "react";

export default function ({title, children, icon}) {
    const [display, setDisplay] = useState(false);

    return (
        <div className="">
            <div className="fw-bold">
                <div role="button"
                     className={"nav-link text-dark d-flex justify-content-between fw-2 pe-1 " + (display ? "" : "collapsed")}
                     onClick={() => setDisplay(!display)}>
                        <span>
                            {icon} <span className="ps-2">{title}</span>
                        </span>
                    <span className="expand d-none d-md-block ps-3">
                            {display ?
                                <i className="fa-solid fa-angle-down"/>
                                :
                                <i className="fa-solid fa-angle-right"/>
                            }
                        </span>
                </div>
            </div>
            {display &&
                <div className="ps-2 pb-3">
                    {children}
                </div>
            }
        </div>

    )
}