import React from "react";

const Modal = function ({title, children, display, setDisplay, size = "md"}) {

    return (display &&
        <>
            <div className="modal d-block" style={{ color: "rgb(76, 86, 106)"}}
                 onClick={(e) => e.target === e.currentTarget && setDisplay(false)} tabIndex="-1">
                <div
                    className={"modal-dialog modal-" + size + " modal-dialog-centered modal-dialog-scrollable modal-fullscreen-md-down"}>
                    <div className="modal-content border-0">
                        <div className="modal-header pb-0 border-0">
                            <h6 className="modal-title">{title}</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    onClick={() => setDisplay(false)}/>
                        </div>
                        <div className="modal-body">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"/>
        </>
    );
}

export default Modal;