import {Link} from "react-router-dom";
import ContactsButtons from "../util/ContactsButtons";

export default function ({mission, c, update}) {

    return (
        <div key={c.case?.id}
             className={"card mt-3 " + (c.progress === "DONE" ? "border-success" : "")}>
            <div className="card-body">
                <div className="row">
                    <div className="col-md">
                        <div className="d-flex justify-content-between mb-2">
                            <Link className="h5 text-decoration-none text-reset"
                                  to={"/dashboard/cases/" + c.case?.id}>
                                {c.progress === "DONE" &&
                                <i className="text-success fa-solid fa-circle-check"/>} {c.case?.name}
                            </Link>
                            <div>
                                {c?.case?.location?.province &&
                                <p className="card-text">
                                    {c?.case?.location?.province.name}
                                </p>
                                }
                            </div>
                        </div>
                        {c?.case?.location?.description &&
                        <p className="card-text">
                            {c?.case?.location?.description}
                        </p>
                        }
                        <div>
                            <Location item={c?.case}/>
                            {c?.case?.contacts &&
                            <ContactsButtons values={c.case.contacts}/>
                            }
                        </div>
                        <div className="mt-3">
                            <select key={c.case.id + c.progress} name="progress" className="form-select"
                                    value={c.progress}
                                    onChange={(v) => update(mission.id, c.case.id, v.target.value)}>
                                <option value={null}>New</option>
                                <option value="PENDING">Pending</option>
                                <option value="INPROGRESS">In Progress</option>
                                <option value="DONE">Done</option>
                            </select>
                        </div>
                        {c.supplies && <hr/>}
                        <div>
                            {c.supplies?.map(s =>
                                <div className="d-flex justify-content-between">
                                    <div>{s.aidType?.name}</div>
                                    <div>{s.amount}</div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Location = function ({item}) {
    if (item?.location?.coordinates || item?.location?.url) {
        return (
            <a className="btn btn-primary me-2"
               href={item.location.coordinates ? "https://www.google.com/maps/place/" + item.location.coordinates : item.location.url}><i
                className="fa-solid fa-map-location-dot"/> Map</a>
        )
    }

    return <></>
}