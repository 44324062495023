import {Field} from "react-final-form";
import Label from "./Label";

const Checkbox = function ({name, label, showLabel = true}) {
    const id = (Math.random() + 1).toString(36).substring(7);
    return (
        <div className="mb-3 form-check">
            <Field name={name} id={id} className="form-check-input" component="input" type="checkbox"/>
            <Label label={label} name={name} showLabel={showLabel}/>
        </div>
    )
}

export default Checkbox;