import {useNavigate} from "react-router-dom";
import Manage from "../management-lib/util/manage/Manage";
import RenderUtil from "../management-lib/util/RenderUtil";
import DataProvider from "../management-lib/util/DataProvider";

export default function({dataProvider = new DataProvider("team")}) {

    const navigate = useNavigate();

    return (
        <Manage create={true} title="Teams" dataProvider={dataProvider} columns={[
            {id: "name", render: i => i.name},
            {id: "timestamp", render: i => RenderUtil.date(i.timestamp)},
            {name: "Members", render: i => i.volunteers?.length},
        ]}
        onClick={(i) => navigate(i.id)}
        />
    )
}