import {Field} from "react-final-form";
import Label from "./Label";
import DatePicker from "react-datepicker";

const DateInput = function ({name, label, undertext, component = 'input', showLabel = true, validate}) {
    return (
        <div className="mb-3">
            <Field name={name} component={component}
                   validate={validate}>
                {({input, meta}) => (
                    <>
                        <Label label={label} showLabel={showLabel} name={name}/>
                        <DatePicker selected={input.value}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    onChange={(date) => {
                            input.onChange(date);
                        }} className={"form-control" + (meta.error && meta.touched? " is-invalid": "")} />
                        {meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>}
                    </>
                )}
            </Field>
            {undertext && <div className="form-text">{undertext}</div>}
        </div>
    )
}

export default DateInput;