import {NavLink, Route, Routes, useParams} from "react-router-dom";

import Cases from "./Cases";
import DataProvider from "../management-lib/util/DataProvider";
import EntityEdit from "../management-lib/util/EntityEdit";
import Input from "../management-lib/util/form/Input";
import ChooseValue from "../management-lib/util/form/ChooseValue";
import Select from "../management-lib/util/form/Select";
import AidSupply from "./AidSupply";
import TextArea from "../management-lib/util/form/TextArea";
import DateInput from "../management-lib/util/form/DateInput";

export default function ({dataProvider = new DataProvider("mission")}) {

    let params = useParams();

    const id = params.id;

    return (
        <EntityEdit id={id} dataProvider={dataProvider} render={() =>
            <div>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <NavLink className="nav-link d-inline-block" end
                                 to="">Basic</NavLink>
                        <NavLink className="nav-link d-inline-block"
                                 to="aid-supply">Aid Supply</NavLink>
                        <NavLink className="nav-link d-inline-block"
                                 to="cases">Cases</NavLink>
                    </li>
                </ul>
                <div className="tab-content p-2">
                    <div className="tab-pane fade show active h-100">
                        <Routes>
                            <Route index element={
                                <>
                                    <DateInput name="timestamp"/>
                                    <Input name="name" placeholder="name" label="Name"/>
                                    <ChooseValue dataProvider={new DataProvider("team")} columns={[
                                        {id: "name", render: (i) => i.name}
                                    ]} objectToId={v => v.id} convertToAttribute={v => v} attributeToId={v => v.id} name="team"
                                                 renderLabel={
                                                     (d) => d.name
                                                 }
                                    />
                                    <div className="col-md">
                                        <Select name="state" label="State"
                                                options={[
                                                    {value: "OPEN", text: "Open"},
                                                    {value: "INPROGRESS", text: "In Progress"},
                                                    {value: "INCOMPLETE", text: "In Complete"},
                                                    {value: "DONE", text: "Done"},
                                                ]}/>
                                    </div>
                                    <TextArea name="description"/>
                                </>
                            }/>
                            <Route path="aid-supply" element={
                                <AidSupply/>
                            }/>
                            <Route path="cases" element={
                               <Cases/>
                            }/>
                        </Routes>
                    </div>
                </div>
            </div>
        }/>
    )
}