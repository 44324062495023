import {Field} from "react-final-form";
import RenderUtil from "../RenderUtil";
import AddValue from "./AddValue";

export default function ({columns, name, dataProvider, matcher = (i1, i2) => (i1.id === i2.id)}) {

    const add = function (onChange, newValue, inputValue) {
        const result = [...inputValue];
        result.push(newValue);
        onChange(result);
    }

    const remove = function(onChange, toRemove, inputValue) {
        let result = [...inputValue];
        result = result.filter((i) => !matcher(toRemove, i));
        onChange(result);
    }

    return (
        <>
            <Field name={name} type="text">
                {({input}) =>
                    <>
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                {columns?.map(c =>
                                    <th>
                                        {c.name ? c.name : RenderUtil.toLabel(c.id)}
                                    </th>
                                )}
                                <th>
                                    Actions
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {input.value && input.value.length > 0 ?
                                [...input?.value].map(r =>
                                    <tr key={r.id}>
                                        {columns.map(c => <td className="align-middle">{c.render(r)}</td>)}
                                        <td>
                                            <a className="btn btn-danger btn-sm" onClick={() => remove(input.onChange, r, input.value)}>
                                                <i className="fa-solid fa-square-minus"/>
                                            </a>
                                        </td>
                                    </tr>
                                ) :
                                <tr>
                                    <td colSpan={columns.length} className="text-center">No Data</td>
                                </tr>
                            }
                            </tbody>
                        </table>
                        <AddValue columns={columns} dataProvider={dataProvider} onChange={(value) => add(input.onChange, value, input.value)}/>
                    </>
                }
            </Field>
        </>
    )
}