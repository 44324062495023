import {NavLink, Route, Routes, useParams} from "react-router-dom";

import Contacts from "../util/Contacts";
import DataProvider from "../management-lib/util/DataProvider";
import EntityEdit from "../management-lib/util/EntityEdit";
import Input from "../management-lib/util/form/Input";
import MultiSelect from "../management-lib/util/form/MultiSelect";

export default function ({dataProvider = new DataProvider("volunteer")}) {

    let params = useParams();

    const id = params.id;

    const options = [
        "root",
        "normal",
    ];

    return (
        <EntityEdit id={id} dataProvider={dataProvider} render={() =>
            <div>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <NavLink className="nav-link d-inline-block" activeClassName="active" end
                                 to="">Basic</NavLink>
                        <NavLink className="nav-link d-inline-block" activeClassName="active"
                                 to="contact-information">Contact Information</NavLink>
                    </li>
                </ul>
                <div className="tab-content p-2">
                    {/*<div className="tab-pane fade show active h-100">*/}
                    <Routes>
                        <Route index element={
                            <>
                                <Input name="name" placeholder="name" label="Name"/>
                                <MultiSelect name="roles" options={options}
                                             convertToValue={(v) => v.value}
                                             convertToOption={(v) => {
                                                 return {value: v, label: v}
                                             }}/>
                            </>
                        }/>
                        <Route path="contact-information" element={
                            <Contacts name="contacts"/>
                        }/>
                    </Routes>
                    {/*</div>*/}
                </div>
            </div>
        }/>
    )
}