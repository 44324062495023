class MissionUtil {

    doneCases(i) {
        return i?.cases?.filter(c => c.progress === "DONE")?.length || 0;
    }

    remainingCases(i) {
        return i?.cases?.filter(c => c.progress !== "DONE")?.length || 0;
    }

    totalCases(i) {
        return i?.cases?.length || 0;
    }

    progress(i) {

        const done = this.doneCases(i);
        const total = this.totalCases(i);

        if (done === total && done === 0) {
            return 0;
        }
        return (done/total) * 100;
    }

    sortMission(mission) {
        mission?.cases?.sort((i1, i2) => {
            const i1Value = this.getValue(i1);
            const i2Value = this.getValue(i2);

            return i1Value - i2Value;
        });
        return mission;
    }

    getValue(i1) {
        if (i1.progress === "DONE") {
            return 5;
        }
        if (i1.progress === "INPROGRESS") {
            console.log("progress");
            return -4;
        }
        if (i1.progress === "PENDING") {
            console.log("pending");
            return -3;
        }

        return -1
    }
}

export default new MissionUtil();