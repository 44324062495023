class LocationUtil {

    dmsToDecimal(dmsString) {
        const convertToDecimal = (degrees, minutes, seconds, direction) => {
            let decimal = parseFloat(degrees) + parseFloat(minutes) / 60 + parseFloat(seconds) / 3600;
            if (direction === 'S' || direction === 'W') {
                decimal = -decimal;
            }
            return decimal;
        };

        const regex = /(\d+)°(\d+)'([\d.]+)"([NSEW])/g;
        let match;
        const coordinates = [];

        while ((match = regex.exec(dmsString)) !== null) {
            const [_, degrees, minutes, seconds, direction] = match;
            coordinates.push(convertToDecimal(degrees, minutes, seconds, direction));
        }

        return coordinates.join(', ');
    }

    isValidLocation(value) {
        if (!value) {
            return false;
        }

        if (value.split(',').length !== 2) {
            value = this.dmsToDecimal(value);
        }

        const [lat, lng] = value.split(',');
        if (isNaN(lat) || isNaN(lng)) {
            return false;
        }

        if (parseFloat(lat) < -90 || parseFloat(lat) > 90) {
            return false;
        }

        return !(parseFloat(lng) < -180 || parseFloat(lng) > 180);
    }

    getDecimalCoordinates(value) {
        if (value.split(',').length !== 2) {
            value = this.dmsToDecimal(value);
        }

        return value;
    }
}

export default new LocationUtil();