import {useParams} from "react-router-dom";
import Readonly from "../management-lib/util/form/Readonly";
import ChooseValue from "../management-lib/util/form/ChooseValue";
import DataProvider from "../management-lib/util/DataProvider";
import Input from "../management-lib/util/form/Input";
import EntityEdit from "../management-lib/util/EntityEdit";
import ValidationUtil from "../management-lib/util/ValidationUtil";
import DateInput from "../management-lib/util/form/DateInput";
import Select from "../management-lib/util/form/Select";

export default function ({dataProvider = new DataProvider("individual")}) {
    let params = useParams();

    const id = params.id;

    return (
        <EntityEdit id={id} dataProvider={dataProvider} render={() =>
            <>
                <Input name="name" validate={ValidationUtil.required}/>
                <DateInput name="birthDate"/>
                <Input name="nationalId"/>
                <Select name="gender" options={[
                    {value: "MALE", text: "Male"},
                    {value: "FEMALE", text: "Female"}
                    ]} validate={ValidationUtil.required}/>
                <ChooseValue showFreeSearch={true} dataProvider={new DataProvider("case")} columns={[
                    {id: "name", render: (i) => i.name}
                ]} objectToId={v => v.id} convertToAttribute={v => v.id} name="caseId" label="Case"
                             renderLabel={
                                 (d) => d.name
                             }
                />
            </>
        }/>
    )
}