import {useNavigate} from "react-router-dom";
import DataProvider from "../management-lib/util/DataProvider";
import Manage from "../management-lib/util/manage/Manage";
import RenderUtil from "../management-lib/util/RenderUtil";
import Badges from "../management-lib/util/Badges";

export default function({dataProvider = new DataProvider("volunteer")}) {

    const navigate = useNavigate();
    return (
        <Manage create={true} title="Volunteers" dataProvider={dataProvider} columns={[
            {id: "name", render: i => i.name},
            {id: "timestamp", render: i => RenderUtil.date(i.timestamp)},
            {name: "Contacts", render: i => <Badges values={i?.contacts?.map(c => c.value)}/>},
        ]}
        onClick={(i) => navigate(i.id)}
        />
    )
}