import Column from "./Column";

export default function ({columns, references, data}) {

    return (
        columns.map(c =>
            <td key={JSON.stringify(c)} className="align-middle">
                <Column column={c} references={references} item={data}/>
            </td>
        )
    )
}