import {useNavigate} from "react-router-dom";
import MissionUtil from "../util/MissionUtil";
import DataProvider from "../management-lib/util/DataProvider";
import Progress from "../management-lib/util/Progress";
import Manage from "../management-lib/util/manage/Manage";
import RenderUtil from "../management-lib/util/RenderUtil";

export default function({dataProvider = new DataProvider("mission")}) {

    const navigate = useNavigate();

    return (
        <Manage create={true} title="Missions" dataProvider={dataProvider} columns={[
            {id: "name", render: i => i.name},
            {id: "timestamp", render: i => RenderUtil.date(i.timestamp)},
            {name: "Team", render: i => i?.team?.name},
            {name: "Cases", render: i => i?.cases?.length || "0"},
            {name: "State", render: i => i?.state || "0"},
            {id: "progress", render: i => <Progress progress={MissionUtil.progress(i)} label={MissionUtil.doneCases(i) + "/" + MissionUtil.totalCases(i)}/>},
        ]}
        onClick={(i) => navigate("/dashboard/missions/" + i.id)}
        />
    )
}