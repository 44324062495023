const LoadingResult = ({message, border = true, size = "lg"}) => {
    return (
        <div className={border ? "container card p-4 shadow-sm" : ""}>
            <h5 className="text-center">
                {message}
            </h5>
            <div className="text-center">
                <span className="spinner-grow text-primary m-4" role="status">
                    <span className="sr-only">Loading...</span>
                </span>
                <span className="spinner-grow text-secondary m-4" role="status">
                    <span className="sr-only">Loading...</span>
                </span>
                <span className="spinner-grow text-success m-4" role="status">
                    <span className="sr-only">Loading...</span>
                </span>
                {size !== "sm" &&
                <>
                    <span className="spinner-grow text-danger m-4" role="status">
                    <span className="sr-only">Loading...</span>
                    </span>
                    <span className="spinner-grow text-warning m-4" role="status">
                    <span className="sr-only">Loading...</span>
                    </span>
                    <span className="spinner-grow text-info m-4" role="status">
                    <span className="sr-only">Loading...</span>
                    </span>
                    <span className="spinner-grow text-dark m-4" role="status">
                    <span className="sr-only">Loading...</span>
                    </span>
                </>
                }
            </div>
        </div>
    );
}

export default LoadingResult;