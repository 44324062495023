import {NavLink, Route, Routes} from "react-router-dom";
import ExtractByMissions from "./ExtractByMissions";
import ExtractByAidDate from "./ExtractByAidDate";

export default function ExtractCases() {
    return (
      <>
          <ul className="nav nav-tabs">
              <li className="nav-item">
                  <NavLink className="nav-link d-inline-block" end
                           to="">By Missions</NavLink>
              </li>
              <li className="nav-item">
                  <NavLink className="nav-link d-inline-block"
                           to="by-aid-date">By Aid Date</NavLink>
              </li>
          </ul>
          <div className="tab-content p-2">
              <div className="tab-pane fade show active h-100">
                  <Routes>
                      <Route index element={<ExtractByMissions/>}/>
                      <Route path="by-aid-date/*" element={<ExtractByAidDate/>}/>
                  </Routes>
              </div>
          </div>
      </>
    )
}