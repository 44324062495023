class ValidationUtil {

    required(v) {
        return v? undefined: "Required";
    }

    email(v) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(v)? undefined: "Not a valid email";
    };

    domain(v) {
        const re = /^[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.[a-zA-Z]{2,}$/;
        return re.test(v)? undefined: "Not a valid domain";
    };

    list() {
        const validators = arguments;
        return (v) => {
            for (let i = 0; i < validators.length; i++) {
                const validator = validators[i];

                const result = validator(v);

                if (result) {
                    return result;
                }
            }
            return undefined;
        }

    }
}

export default new ValidationUtil();