export default function () {
    return (
        <main className="flex-shrink-0">
            <div className="container">
                <div className="card p-5 mt-5">
                    <h1 className="">Not Found</h1>
                    <p className="lead">The page you are looking for can not be found, <a
                        href="/">click here</a> to go home</p>
                </div>
            </div>
        </main>
    )
}