import {useNavigate} from "react-router-dom";
import DataProvider from "../management-lib/util/DataProvider";
import Manage from "../management-lib/util/manage/Manage";
import RenderUtil from "../management-lib/util/RenderUtil";

export default function ({dataProvider = new DataProvider("individual"), displayName = false}) {

    const navigate = useNavigate();

    let columns = [
        {id: "name"},
        {id: "birthDate", render: (i) => RenderUtil.date(i.birthDate)},
        {
            id: "caseId",
            name: "Case",
            reference: {
                source: "case", key: "caseId", filter: "id"
            },
            render: c => c.name
        },
        {id: "nationalId"},
    ];

    if (displayName) {
        columns = [
            {id: "case.name", name: "Name"},
            ...columns
        ]
    }


    return (
        <Manage showFreeSearch={true} create={true} title="Individuals" dataProvider={dataProvider}
                columns={columns}
                onClick={(i) => navigate("/dashboard/individuals/" + i.id)}
                onCreate={() => navigate("/dashboard/individuals/new")}
        />
    )
}