import ReferenceTable from "../management-lib/util/form/ReferenceTable";
import DataProvider from "../management-lib/util/DataProvider";
import RenderUtil from "../management-lib/util/RenderUtil";
import Badges from "../management-lib/util/Badges";

export default function () {

   return (
       <ReferenceTable dataProvider={new DataProvider("volunteer")} name="volunteers" columns={[
           {id: "name", render: i => i.name},
           {id: "timestamp", render: i => RenderUtil.date(i.timestamp)},
           {name: "Contacts", render: i => <Badges values={i?.contacts?.map(c => c.value)}/>},
       ]}/>
    )
}