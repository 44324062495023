import {useEffect, useState} from "react";
import MissionsView from "./MissionsView";
import DataProvider from "../management-lib/util/DataProvider";
import LoadingResult from "../management-lib/util/LoadingResult";

export default function () {
    const [missions, setMissions] = useState([]);
    const dataProvider = new DataProvider("mission");
    const [loading, setLoading] = useState(true);

    const load = function () {
        dataProvider.custom("current", "GET").then(data => {
            console.log(data);
            setMissions(data);
            setLoading(false);
        });
    }

    useEffect(() => {
        load();
    }, [])

    if (loading) {
        return <LoadingResult border={false}/>
    }

    return (
        <MissionsView title="My Missions" missions={missions}/>
    )
}