import ActionButton from "./ActionButton";
import {useFormState} from "react-final-form";
import {useState} from "react";
import {toast} from "react-hot-toast";
import {useNavigate} from "react-router-dom";

const DeleteButton = function({dataProvider}) {
    const state = useFormState();
    const [className, setClassName] = useState("btn-primary");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const create = !state.values?.id;

    if (create) {
        return <></>
    }

    const action = function() {
        setLoading(true);
        const data = state.values;
        dataProvider.delete(data.id).then(() => {
            toast.success("Saved!");
            setClassName("btn-success");
            setLoading(false);
            navigate(-1);
        }).catch(() => {
            toast.error("Failed!");
            setClassName("btn-danger");
            setLoading(false);
        })
    }

    return (
        <ActionButton className={className} disabled={!state.pristine || state.submitting || loading} onClick={() => action()} label={"Delete"} loading={state.submitting || loading}/>
    )
}

export default DeleteButton;