import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import ContactsButtons from "../util/ContactsButtons";
import DataProvider from "../management-lib/util/DataProvider";

export default function () {
    const [obj, setObj] = useState({});

    const params = useParams();
    const id = params.id;

    useEffect(() => {
        new DataProvider("share-list").custom("public?id=" + id, 'GET').then(response => {
           setObj(response);
        });
    }, []);

    return (
        <main className="container">
            <div className="my-3 p-3 bg-body rounded shadow-sm">
                <h6 className="border-bottom pb-2 mb-0">{obj.name}</h6>
                {obj?.cases?.map(c =>
                    <div className="d-flex text-muted pt-3">
                        <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <strong className="text-gray-dark">{c?.name}</strong>
                                    {c?.location?.province && <span className="d-block mt-3">{c?.location?.province}</span>}
                                </div>
                                <div>
                                    {c?.location?.url &&
                                    <a href={c.location.url} className="btn btn-primary me-2">
                                        <i className="fa-solid fa-map-location-dot"/> Map
                                    </a>
                                    }
                                    <ContactsButtons values={c?.contacts}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </main>
    )
}