import {useNavigate} from "react-router-dom";
import DataProvider from "../management-lib/util/DataProvider";
import Manage from "../management-lib/util/manage/Manage";

export default function({dataProvider = new DataProvider("case-type")}) {

    const navigate = useNavigate();

    return (
        <Manage create={true} title="Case Types" dataProvider={dataProvider} columns={[
            {id: "name"}
        ]}
        onClick={(i) => navigate(i.id)}
        />
    )
}