import {Field} from "react-final-form";
import Label from "./Label";

const Select = function ({name, label, undertext, options, showLabel = true, validate, key}) {
    return (
        <div className="mb-3" key={key}>
            <Label label={label} showLabel={showLabel} name={name}/>
            <Field name={name} component="select"
                   validate={validate}>
                {({input, meta}) => (
                    <>
                        <select {...input} className={"form-select" + (meta.error && meta.touched? " is-invalid": "")}>
                            {options.map((option) => {
                                return (
                                    <option key={option.value} value={option.value}>{option.text}</option>
                                )
                            })}
                        </select>
                        {meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>}
                    </>
                )}
            </Field>
            {undertext && <div className="form-text">{undertext}</div>}
        </div>
    )
}

export default Select;