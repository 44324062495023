import Input from "../management-lib/util/form/Input";
import Select from "../management-lib/util/form/Select";
import ArrayField from "../management-lib/util/form/ArrayField";
import ValidationUtil from "../management-lib/util/ValidationUtil";

const Contacts = function () {
    return (
        <ArrayField name="contacts" render={({name}) =>
            <>
                <Input name={`${name}.description`} label="Description"/>
                <Select name={`${name}.type`} label="Type" options={[
                    {value: null, text: "Choose..."},
                    {value: "MOBILE", text: "Mobile"},
                    {value: "PHONE", text: "Phone"},
                    {value: "EMAIL", text: "Email"}
                ]}/>
                <Input name={`${name}.value`} label="Value" validate={ValidationUtil.required}/>
            </>
        }
        />
    )
}

export default Contacts;