import ArrayField from "../management-lib/util/form/ArrayField";
import ChooseValue from "../management-lib/util/form/ChooseValue";
import DataProvider from "../management-lib/util/DataProvider";
import Select from "../management-lib/util/form/Select";
import Input from "../management-lib/util/form/Input";
import ArrayTable from "../management-lib/util/form/ArrayTable";

export default function () {
    return (
        <ArrayField name="cases" render={({name}) =>
            <div className="mt-2">
                <div className="row">
                    <div className="col-md">
                        <ChooseValue showFreeSearch={true} key={`${name}.case`} name={`${name}.case`} dataProvider={new DataProvider("case")} columns={[
                            {id: "name", render: (i) => i.name},
                            {name: "Province", render: i => <Location item={i}/>},
                        ]} objectToId={v => v.id} convertToAttribute={v => v} attributeToId={v => v.id} label="Case"
                                     renderLabel={
                                         (d) => d.name
                                     }
                        />


                    </div>
                    <div className="col-md">
                        <Select key={`${name}.progress`} name={`${name}.progress`} label="Progress"
                                options={[
                                    {value: null, text: "New"},
                                    {value: "PENDING", text: "Pending"},
                                    {value: "INPROGRESS", text: "In Progress"},
                                    {value: "DONE", text: "Done"},
                                ]}/>
                    </div>
                </div>
                <ArrayTable columns={["Aid", "Amount"]} name={`${name}.supplies`} addLabel="Add Aid" render={({name}) =>
                    <>
                        <td>
                            <ChooseValue key={`${name}.aidType`} showButton={false} showLabel={false}
                                         name={`${name}.aidType`}
                                         renderLabel={(d) => d.name} columns={[
                                {id: "name"}
                            ]} objectToId={v => v.id} convertToAttribute={v => v} attributeToId={v => v.id} dataProvider={new DataProvider("aid-type")}/>
                        </td>
                        <td>
                            <Input name={`${name}.amount`} showLabel={false} component="number"/>
                        </td>
                    </>
                }/>
            </div>
        }
        />
    )
}

const Location = function({item}) {

    if (!item.location) {
        return <>Not Available</>
    }

    if (item.location.url) {
        return <a href={item.location.url}>{item.location.province?.name || "Map"}</a>
    }

    if (item.location.coordinates) {
        return <a href={"https://www.google.com/maps/place/" + item.location.coordinates}>{item.location.province?.name || "Map"}</a>
    }

    return <>{item.location?.province?.name || "Not Available"}</>
}