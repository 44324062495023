import {NavLink, Route, Routes, useParams} from "react-router-dom";

import Cases from "./Cases";
import DataProvider from "../management-lib/util/DataProvider";
import EntityEdit from "../management-lib/util/EntityEdit";
import Input from "../management-lib/util/form/Input";
import Checkbox from "../management-lib/util/form/Checkbox";

export default function ({dataProvider = new DataProvider("share-list")}) {

    let params = useParams();

    const id = params.id;

    return (
        <EntityEdit id={id} dataProvider={dataProvider} render={() =>
            <div>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <NavLink className="nav-link d-inline-block" activeClassName="active" end
                                 to="">Basic</NavLink>
                        <NavLink className="nav-link d-inline-block" activeClassName="active"
                                 to="cases">Cases</NavLink>
                    </li>
                </ul>
                <div className="tab-content p-2">
                    <div className="tab-pane fade show active h-100">
                        <Routes>
                            <Route index element={
                                <>
                                    <Input name="name"/>
                                    <Input name="description" component="textarea"/>
                                    <Checkbox name="available" label="Public"/>
                                </>
                            }/>
                            <Route path="cases" element={
                               <Cases/>
                            }/>
                        </Routes>
                    </div>
                </div>
            </div>
        }/>
    )
}