import {useNavigate} from "react-router-dom";
import DataProvider from "../management-lib/util/DataProvider";
import Manage from "../management-lib/util/manage/Manage";
import RenderUtil from "../management-lib/util/RenderUtil";
import Badges from "../management-lib/util/Badges";

export default function({dataProvider = new DataProvider("case")}) {

    const navigate = useNavigate();

    return (
        <Manage showFreeSearch={true} create={true} title="Cases" onCreate={() => navigate("/dashboard/cases/new")} dataProvider={dataProvider} columns={[
            {id: "name", render: i => i.name},
            {id: "timestamp", render: i => RenderUtil.date(i.timestamp)},
            {id: "type", render: i => <Badges values={i.types?.map(v => v.name)}/>},
            {id: "status", render: i => i.status},
            {name: "Contacts", render: i => <Badges values={i?.contacts?.map(c => c.value)}/>},
            {name: "Province", render: i => <Location item={i}/>},
            {name: "Map", render: i => RenderUtil.boolean(i.location?.url || i.location?.coordinates)},
        ]}
                onClick={(i) => navigate(i.id)}
        />
    )
}

export const Location = function({item}) {

    if (!item?.location) {
        return <>Not Available</>
    }

    if (item.location.url) {
        return <a target="_blank" href={item.location.url}>{item.location.province?.name || "Map"}</a>
    }

    if (item.location.coordinates) {
        return <a target="_blank" href={"https://www.google.com/maps/place/" + item.location.coordinates}>{item.location.province?.name || "Map"}</a>
    }

    return <>{item.location?.province?.name || "Not Available"}</>
}