import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import LoadingResult from "../LoadingResult";
import RenderUtil from "../RenderUtil";
import DataProvider from "../DataProvider";
import {getProperty} from "dot-prop";
import Toolbar from "./Toolbar";
import Pagination from "./Pagination";
import Table from "./table/Table";

const Manage = function ({
                             dataProvider,
                             columns,
                             initialPageSize = 10,
                             onClick,
                             create = false,
                             title,
                             showTitle = true,
                             fields,
                             onCreate,
                             showAutoReload = false,
                             showFreeSearch = false
                         }) {
    const [data, setData] = useState([]);
    const [references, setReferences] = useState({});
    const [total, setTotal] = useState(0);
    const [sort, setSort] = useState();
    const [order, setOrder] = useState();
    const [page, setPage] = useState(0);
    const [filters, setFilters] = useState([]);
    const [pageSize, setPageSize] = useState(initialPageSize)
    const [loading, setLoading] = useState(true);
    const [text, setText] = useState();

    const [autoRefresh, setAutoRefresh] = useState(undefined);

    const navigate = useNavigate();

    const get = function () {
        setLoading(true);

        const start = page * pageSize;
        const end = page * pageSize + pageSize;

        let sortBy;
        let orderBy;

        if (sort && order) {
            sortBy = sort;
            orderBy = order;
        }

        if (text) {
            dataProvider.text(start, end, orderBy, sortBy, text).then(response => {
                processResults(response);
            });
        } else {
            dataProvider.get(start, end, orderBy, sortBy, filters).then(response => {
                processResults(response);
            });
        }
    }

    const processResults = function (response) {

        setData(response.data);
        setTotal(response.total);
        setLoading(false);


        const rs = {};
        let promises = [];
        columns.forEach((c) => {
            if (!c.reference) {
                return;
            }

            const ids = []
            response.data.forEach(d => {
                ids.push(getProperty(d, c.reference.key));
            })

            dataProvider = new DataProvider(c.reference.source);

            promises.push(dataProvider.ids(ids).then(data => {
                rs[c.id] = data;
            }));
        })

        Promise.all(promises).then(() => {
            setReferences(rs);
        });
    }

    useEffect(() => {
        get();
    }, [dataProvider, page, sort, order, filters, pageSize]);

    title = title || dataProvider.getLabel() || RenderUtil.toLabel(dataProvider.getEntity());

    const pages = !loading && Math.ceil(total / pageSize);

    if (loading && !autoRefresh) {
        return <LoadingResult border={false}/>;
    }

    return (
        <>
            <div className="d-flex w-100 justify-content-between mb-3">
                {(!showFreeSearch || showTitle) && title && <h4 className="me-3">{showTitle && title}</h4>}
                {showFreeSearch &&
                    <input autoFocus={true} className="form-control me-3" value={text} onChange={e => setText(e.target.value)} type="text" placeholder="Search..."
                           onKeyPress={event => {
                               if (event.key === 'Enter') {
                                   get();
                               }
                           }}
                    />
                }
                <FilterBadges filters={filters} setFilters={setFilters}/>
                <Toolbar dataProvider={dataProvider} onCreate={onCreate} create={create} onRefresh={() => get()}
                         setAutoRefresh={setAutoRefresh} setFilters={setFilters} filters={filters}
                         fields={fields || columns.filter(c => c.id)} showAutoReload={showAutoReload}/>
            </div>
            <Table columns={columns} onClick={onClick} data={data} references={references} sort={sort} setSort={setSort}
                   setOrder={setOrder} order={order}/>
            <Pagination total={total} pages={pages} setPage={setPage} page={page} pageSize={pageSize} setPageSize={setPageSize}/>
        </>
    )
}

const FilterBadges = function ({filters, setFilters}) {
    console.log(filters);

    const removeFilter = function (filter) {
        let result = [...filters];
        result = result.filter(f => f.name !== filter.name && filter.value !== f.value);
        setFilters(result);
    }

    if (!filters || !filters.length) {
        return <></>
    }

    return (
        <div className="filter-badge rounded-3 p-1 w-50 mx-2 h5 my-0">
            {filters?.map(f =>
                <span className="badge bg-primary m-0 p-2">{f?.name}: {f?.value}
                    <span role="button" className="ms-1" onClick={() => removeFilter(f)}>
                    <i className="fas fa-times"/>
                </span>
                </span>
            )}
        </div>
    )
}

export default Manage;