import Row from "./Row";

export default function({data, onClick, columns, references}) {

    if (!data || data.length === 0) {
        return (
            <tr key={"none"}>
                <td colSpan={columns.length} className="text-center">No Data</td>
            </tr>
        )
    }

    return (
        data?.map(r =>
            <tr key={JSON.stringify(r)} role={onClick ? "button" : ""} onClick={() => onClick && onClick(r)}>
                <Row columns={columns} references={references} data={r}/>
            </tr>
        )
    )
}