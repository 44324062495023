import {Link} from "react-router-dom";

import MissionUtil from "../util/MissionUtil";
import Progress from "../management-lib/util/Progress";
import {getProperty} from "dot-prop";

export default function ({missions, title}) {

    function getUsed(mission, aidType) {
        return mission.cases?.filter(c => c.progress === 'DONE').reduce((partialSum, a) => {
            const subtotal = a?.supplies?.filter(f => f.aidType?.id === aidType?.id).reduce((sum, b) => sum + b.amount, 0) || 0;
            console.log(subtotal);
            return partialSum + subtotal;
        } , 0);
    }

    function getAssigned(mission, aidType) {
        return mission.cases?.reduce((partialSum, a) => {
            const subtotal = a?.supplies?.filter(f => f.aidType?.id === aidType?.id).reduce((sum, b) => sum + b.amount, 0) || 0;
            return partialSum + subtotal;
        }, 0);
    }

    return (
        <>
            <h5>{title}</h5>
            {missions && missions.length !== 0 ?
                missions.map(m =>
                    <div className="card mt-3">
                        <div className="card-body">
                            <div className="card-title d-flex justify-content-between">
                                <Link className="text-decoration-none text-reset" to={"/dashboard/mission-view/" + m.id}>
                                    <span className="h5 me-3">{m.name}</span>
                                    <span
                                        className="text-muted small">{MissionUtil.doneCases(m)}/{m.cases?.length} cases</span>
                                </Link>
                                <div>
                                    <Link className="text-decoration-none text-reset" to={"/dashboard/teams/" + m.team?.id}>{m?.team?.name} <span className="text-muted small">({m.team?.volunteers?.length || 0})</span></Link>
                                </div>
                            </div>
                            <div className="mt-2">
                                <Progress progress={MissionUtil.progress(m)}
                                          label={MissionUtil.doneCases(m) + "/" + MissionUtil.totalCases(m)}/>
                            </div>
                            {m.supplies && <hr/>}
                            <div>
                                {m.supplies?.map(s =>
                                    <div className="d-flex justify-content-between">
                                        <div>{s.aidType?.name}</div>
                                        <div>{getAssigned(m, s.aidType)} assigned, {getUsed(m, s.aidType)} used of {s.amount} total</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )
                :
                <>
                    <p>No active missions were found, <Link to="/dashboard/missions">click here</Link> to navigate to
                        missions.</p>
                </>
            }
        </>
    )
}