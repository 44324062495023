import {useState} from "react";
import Label from "./Label";

export default function FileUpload({uploadUrl, parameter, filename, label, type = "detailed", showLabel = true, onComplete}) {
    const [selectedFile, setSelectedFile] = useState();
    const [loading, setLoading] = useState();
    const [state, setState] = useState(0);

    const handleSubmission = (selectedFile) => {
        setLoading(true);
        const formData = new FormData();

        formData.append('file', selectedFile);
        formData.append(parameter, filename);

        fetch(
            uploadUrl,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                console.log('Success:', result);
                setLoading(false);
                setState(1);
                if (onComplete) {
                    onComplete();
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
                setState(2);
                if (onComplete) {
                    onComplete();
                }
            });
    };

    if (type === "detailed") {
        return (
            <div>
                <Label label={label} showLabel={showLabel} name={parameter}/>
                <div className="input-group mb-3">
                    <input type="file" className="form-control" onChange={(e) => setSelectedFile(e.target.files[0])}/>
                    <a className={"btn" + (state === 0 ? " btn-outline-primary" : state === 1 ? " btn-outline-success" : " btn-outline-danger")}
                       onClick={() => handleSubmission(selectedFile)}>
                        {loading ?
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            :
                            <>Upload</>
                        }</a>
                </div>
            </div>
        )
    } else {
        return (
            <div className={"btn position-relative me-2 " + (state === 0 ? " btn-outline-primary" : state === 1 ? " btn-outline-success" : " btn-outline-danger")}>
                <input type="file" className="form-control opacity-0 top-0 end-0 position-absolute" onChange={(e) => handleSubmission(e.target.files[0])}/>
                <a
                   onClick={() => handleSubmission()}>
                    {loading ?
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        :
                        <>{label}</>
                    }
                </a>
            </div>
        )
    }
}