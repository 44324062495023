import {NavLink, Route, Routes, useParams} from "react-router-dom";

import Members from "./Members";
import {useEffect, useState} from "react";
import DataProvider from "../management-lib/util/DataProvider";
import EntityEdit from "../management-lib/util/EntityEdit";
import Input from "../management-lib/util/form/Input";
import Badges from "../management-lib/util/Badges";

export default function ({dataProvider = new DataProvider("team")}) {
    const [volunteers, setVolunteers] = useState([]);

    let params = useParams();

    const id = params.id;

    useEffect(() => {
        const dataProvider = new DataProvider("volunteer");

        dataProvider.get().then(response => {
            setVolunteers(response.data);
        })
    }, []);

    return (
        <EntityEdit id={id} dataProvider={dataProvider} render={() =>
            <div>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <NavLink className="nav-link d-inline-block" activeClassName="active" end
                                 to="">Basic</NavLink>
                        <NavLink className="nav-link d-inline-block" activeClassName="active"
                                 to="members">Member</NavLink>
                    </li>
                </ul>
                <div className="tab-content p-2">
                    <div className="tab-pane fade show active h-100">
                        <Routes>
                            <Route index element={
                                <>
                                    <Input name="name" placeholder="name" label="Name"/>
                                </>
                            }/>
                            <Route path="members" element={
                                <Members name="volunteers" columns={[
                                    {id: "name", render: (i) => i?.name},
                                    {id: "contacts", render: (i) => <Badges values={i?.contacts?.map(c => c.value)}/>},
                                ]} options={volunteers?.map(v => {return {value: v.id, label: v.name}})} values={volunteers} matcher={(o, i) => o.id === i}/>
                            }/>
                        </Routes>
                    </div>
                </div>
            </div>
        }/>
    )
}