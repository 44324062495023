import ArrayField from "../management-lib/util/form/ArrayField";
import ChooseValue from "../management-lib/util/form/ChooseValue";
import DataProvider from "../management-lib/util/DataProvider";

export default function () {
    return (<div className="mt-3">
        <ArrayField name="cases" render={({name}) =>
            <div className="row">
                <div className="col-md">
                    <ChooseValue showFreeSearch={true} dataProvider={new DataProvider("case")}
                                 columns={[
                                     {id: "name"}
                                 ]}
                                 objectToId={v => v.id}
                                 convertToAttribute={v => v}
                                 attributeToId={v => v.id}
                                 name={name} label="Case"
                                 renderLabel={
                                     (d) => d.name
                                 }
                    />
                </div>
            </div>}/>
    </div>)
}