import ArrayField from "../management-lib/util/form/ArrayField";
import ChooseValue from "../management-lib/util/form/ChooseValue";
import DataProvider from "../management-lib/util/DataProvider";
import Select from "../management-lib/util/form/Select";
import Input from "../management-lib/util/form/Input";
import {useFormState} from "react-final-form";
import {getProperty} from "dot-prop";

export default function () {
    const state = useFormState();

    const values = state.values;

    function getUsed(aidType) {
        return values.cases?.filter(c => c.progress === 'DONE').reduce((partialSum, a) => {
            const subtotal = a?.supplies?.filter(f => f.aidType?.id === aidType?.id).reduce((sum, b) => sum + b.amount, 0) || 0;
            console.log(subtotal);
            return partialSum + subtotal;
        } , 0);
    }

    function getAssigned(aidType) {
        return values.cases?.reduce((partialSum, a) => {
            const subtotal = a?.supplies?.filter(f => f.aidType?.id === aidType?.id).reduce((sum, b) => sum + b.amount, 0) || 0;
            return partialSum + subtotal;
        }, 0);
    }

    return (
        <>
            <ArrayField name="supplies" render={({name}) =>
                <div className="mt-2">
                    <div className="row">
                        <div className="col-md">

                            <ChooseValue key={`${name}.aidType`} label="Aid Type" name={`${name}.aidType`}
                                         renderLabel={(d) => d.name} columns={[
                                {id: "name"}
                            ]} objectToId={v => v.id} convertToAttribute={v => v} attributeToId={v => v.id} dataProvider={new DataProvider("aid-type")}/>


                        </div>
                        <div className="col-md">
                            <Input name={`${name}.amount`} label="Amount" component="number"/>
                        </div>
                    </div>
                    <div>
                        Currently {getAssigned(getProperty(state.values, `${name}.aidType`))} assigned and {getUsed(getProperty(state.values, `${name}.aidType`))} used.
                    </div>
                </div>
            }
            />
        </>
    )
}