import {Field} from "react-final-form";
import Label from "./Label";

export default function ({name, label, render, hideOnEmpty = true, showLabel = true}) {

    return (
        <Field name={name} type="text">
            {({input}) =>
                (!hideOnEmpty || input.value)?
                <div className="mb-3">
                    <Label label={label} showLabel={showLabel} name={name}/>
                    <span className="form-control">{render ? render(input.value) : input.value}</span>
                </div>
                    :
                    ""
            }
        </Field>
    )
}