import DataProvider from "../management-lib/util/DataProvider";
import {useEffect, useState} from "react";
import DateInput from "../management-lib/util/form/DateInput";
import arrayMutators from "final-form-arrays";
import Checkbox from "../management-lib/util/form/Checkbox";
import {Form} from "react-final-form";
import {Link, NavLink, Route, Routes} from "react-router-dom";
import Badges from "../management-lib/util/Badges";
import RenderUtil from "../management-lib/util/RenderUtil";
import {Location} from "../case/CaseList";
import MapView from "../map/MapView";
import ActionButton from "../management-lib/util/form/ActionButton";

export default function ExtractByAidDate() {
    const [cases, setCases] = useState([]);
    const [loading, setLoading] = useState(false);

    function get(values) {
        setLoading(true);
        new DataProvider("case").custom(`by-mission-date`, "POST", values).then(d => {
            setCases(d);
            setLoading(false);
        });
    }

    return (
        <Form onSubmit={(v, f) => get(v, f)} mutators={{...arrayMutators}}
              render={({form, values, handleSubmit, pristine, submitting}) => (
                  <>
                      <div className="card p-3 mt-4">
                          <DateInput name="since"/>
                          <Checkbox name="included"/>
                          <ActionButton className="btn-primary" disabled={loading}
                                        onClick={form.submit}
                                        label={"Search"} loading={loading}/>
                      </div>
                      <div className="my-3">Count: {cases.length}</div>
                      <ul className="nav nav-tabs mt-5">
                          <li className="nav-item">
                              <NavLink className="nav-link d-inline-block" end
                                       to="">List</NavLink>
                          </li>
                          <li className="nav-item">
                              <NavLink className="nav-link d-inline-block"
                                       to="map">Map</NavLink>
                          </li>
                      </ul>
                      <div className="tab-content p-2">
                          <div className="tab-pane fade show active h-100">
                              <Routes>
                                  <Route index element={
                                      <table className="table table-hover mt-4">
                                          <thead>
                                          <tr>
                                              <th>Mission</th>
                                              <th>Timestamp</th>
                                              <th>Name</th>
                                              <th>Contacts</th>
                                              <th>Map</th>
                                          </tr>
                                          </thead>
                                          <tbody>
                                          {cases.map(c =>
                                              <tr>
                                                  <td><a target="_blank" href={"dashboard/missions/" + c?.missionId}>{c?.missionName}</a></td>
                                                  <td>{RenderUtil.date(c?.timestamp) || "-"}</td>
                                                  <td><a target="_blank" href={"/dashboard/cases/" + c?.case?.id}>{c?.case?.name}</a></td>
                                                  <td><Badges values={c.case?.contacts?.map(c => c.value)}/></td>
                                                  <td><Location item={c?.case}/></td>
                                              </tr>
                                          )}
                                          </tbody>
                                      </table>
                                  }/>
                                  <Route path="map" element={<MapView cases={cases.map(c => c?.case)}/>}/>
                              </Routes>
                          </div>
                      </div>
                  </>
              )}/>
    )
}