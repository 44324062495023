import arrayMutators from "final-form-arrays";
import {Form, useFormState} from "react-final-form";
import ArrayField from "../management-lib/util/form/ArrayField";
import ChooseValue from "../management-lib/util/form/ChooseValue";
import DataProvider from "../management-lib/util/DataProvider";
import {useEffect, useState} from "react";
import Badges from "../management-lib/util/Badges";
import {Location} from "../case/CaseList";
import Checkbox from "../management-lib/util/form/Checkbox";
import RenderUtil from "../management-lib/util/RenderUtil";
import Progress from "../management-lib/util/Progress";
import MissionUtil from "../util/MissionUtil";
import Button from "bootstrap/js/src/button";
import Modal from "../management-lib/util/Modal";
import TextArea from "../management-lib/util/form/TextArea";
import {Link} from "react-router-dom";

export default function ExtractByMissions() {

    function save() {

    }

    return (
        <Form onSubmit={(v, f) => save(v, f)} mutators={{...arrayMutators}}
              render={({form, values, handleSubmit, pristine, submitting}) => (
                  <form onSubmit={handleSubmit}>
                      <div className="row">
                          <div className="col-4">
                              <div>Add missions to extract cases from</div>
                              <ArrayField newValue={""} name={"missionIds"} render={({name}) =>
                                  <ChooseValue showFreeSearch={true} dataProvider={new DataProvider("mission")}
                                               columns={[
                                                   {id: "name", render: i => i.name},
                                                   {id: "timestamp", render: i => RenderUtil.date(i.timestamp)},
                                                   {name: "Team", render: i => i?.team?.name},
                                                   {name: "Cases", render: i => i?.cases?.length || "0"},
                                                   {name: "State", render: i => i?.state || "0"},
                                                   {id: "progress",
                                                       render: i => <Progress progress={MissionUtil.progress(i)}
                                                                              label={MissionUtil.doneCases(i) + "/" + MissionUtil.totalCases(i)}/>
                                                   },
                                               ]} objectToId={v => v.id} convertToAttribute={v => v.id} name={`${name}`}
                                               label="Mission"
                                               renderLabel={
                                                   (d) => d.name
                                               }
                                  />
                              }/>
                          </div>
                          <div className="col-8">
                              <Checkbox name="onlyDone" label={"Show only cases with progress DONE"}/>
                              <Checkbox name="notDone" label={"Show only cases with progress NOT DONE"}/>
                              <ExportCSV/>
                              <CaseList/>
                          </div>
                      </div>
                  </form>
              )}/>
    )
}

function CaseList() {
    const [missions, setMissions] = useState([]);

    const form = useFormState()?.values;

    const values = form?.missionIds;

    const onlyDone = form?.onlyDone;

    const notDone = form?.notDone;

    console.log("FORM", form);

    useEffect(() => {
        if (values) {
            new DataProvider("mission").ids(values).then(r => {
                console.log("response", r);
                setMissions(r);
            });
        }
    }, [values]);

    return (
        <table className="table table-hover">
            <thead>
            <tr>
                <th>Mission</th>
                <th>Timestamp</th>
                <th>Name</th>
                <th>Contacts</th>
                <th>Map</th>
                <th>Aid</th>
                <th>Progress</th>
            </tr>
            </thead>
            <tbody>
            {missions?.map(m =>
                m.cases.map(c =>
                    <Case mission={m} c={c} onlyDone={onlyDone} notDone={notDone}/>
                ))}
            </tbody>
        </table>
    )
}

function Case({mission, c, onlyDone, notDone}) {
    console.log(onlyDone);
    console.log(notDone);

    if (c?.progress === "DONE" && notDone) {
        return <></>
    }

    if (c?.progress !== "DONE" && onlyDone) {
        return <></>
    }

    return (
        <tr>
            <td><a target="_blank" href={"dashboard/missions/" + mission?.id}>{mission?.name}</a></td>
            <td>{RenderUtil.date(mission?.timestamp)}</td>
            <td><a target="_blank" href={"/dashboard/cases/" + c?.case?.id}>{c?.case?.name}</a></td>
            <td><Badges values={c.case?.contacts?.map(c => c.value)}/></td>
            <td><Location item={c?.case}/></td>
            <td>
                {c?.supplies?.map(s =>
                    <div className="d-flex justify-content-between">
                        <div>{s?.aidType?.name}</div>
                        <div>{s?.amount}</div>
                    </div>
                )}
            </td>
            <td>{c?.progress}</td>
        </tr>
    )
}

function ExportCSV() {
    const [display, setDisplay] = useState();
    const [csv, setCsv] = useState();

    const form = useFormState()?.values;

    const missionIds = form?.missionIds;

    const onlyDone = form?.onlyDone;

    const notDone = form?.notDone;

    useEffect(() => {
        if (missionIds) {
            new DataProvider("mission").ids(missionIds).then(r => {
                let result = "";
                r.map(mission =>
                    mission.cases.map(c => {
                        if (c?.progress === "DONE" && notDone) {
                            return;
                        }

                        if (c?.progress !== "DONE" && onlyDone) {
                            return;
                        }

                        result += mission?.name;
                        result += ",";
                        result += RenderUtil.date(mission?.timestamp);
                        result += ",";
                        result += c?.case?.name;
                        result += ",";
                        result += c.case?.contacts?.map(c => c.value);
                        result += ",";
                        if (c?.case?.location?.coordinates) {
                            result += "https://www.google.com/maps/place/" + c?.case?.location?.coordinates;
                        }
                        result += ",";
                        result += c.supplies?.map(s => s?.aidType?.name + " " + s.amount).join("; ");
                        result += ",";
                        result += c?.progress;
                        result += "\n";
                    })
                );
                setCsv(result);
            });
        }
    }, [form]);

    return (
        <>
            {csv &&
                <a download="export.csv" href={`data:application/octet-stream,${encodeURIComponent(csv)}`}>Download CSV</a>
            }
        </>
    )
}