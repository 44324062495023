import moment from "moment";

const RenderUtil = function () {
    this.date = function (rawDate) {
        if (rawDate) {
            return moment(new Date(rawDate)).format("YYYY-MM-DD HH:mm");
        }
    }

    this.boolean = function (rawBoolean) {
        return rawBoolean ? "Yes" : "No"
    }

    this.toLabel = function(text) {
        if (!text) {
            return;
        }
        const result = text.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    this.url = function(url, name) {
        return <a href={url}>{name}</a>
    }
}

export default new RenderUtil();