const ActionButton = function({disabled, loading, onClick, label = "Submit", className}) {
    return (
        <button className={"btn " + className} type="button" disabled={disabled}
                onClick={onClick}>
            {
                loading ?
                    <span className="spinner-border spinner-border-sm" role="status"
                          aria-hidden="true"/>
                    :
                    <>{label}</>
            }
        </button>
    )
}

export default ActionButton;