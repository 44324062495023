import {useNavigate} from "react-router-dom";
import DataProvider from "../management-lib/util/DataProvider";
import Manage from "../management-lib/util/manage/Manage";
import RenderUtil from "../management-lib/util/RenderUtil";

export default function ({dataProvider = new DataProvider("aid-record"), displayName = false}) {

    const navigate = useNavigate();

    let columns = [
        {id: "timestamp", render: (i) => RenderUtil.date(i.timestamp)},
        {id: "type", render: (i) => i?.type?.name},
        {id: "description"},
    ];

    if (displayName) {
        columns = [
            {id: "case.name", name: "Name"},
            ...columns
        ]
    }


    return (
        <Manage create={true} title="Aid Records" dataProvider={dataProvider}
                columns={columns}
                onClick={(i) => navigate("/dashboard/aid-records/" + i.id)}
                onCreate={() => navigate("/dashboard/aid-records/new")}
        />
    )
}