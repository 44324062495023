export default function ({pageSize, setPageSize}) {
    return (
        <div>
            <select className="form-select d-inline" value={pageSize}
                    onChange={(e) => setPageSize(parseInt(e.target.value))}>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={25}>25</option>
                <option value={30}>30</option>
                <option value={35}>35</option>
            </select>
        </div>
    )
}