import RenderUtil from "../RenderUtil";

export default function ({showLabel, label, name}) {

    if (!showLabel) {
        return <></>
    }

    return (
        <label className="form-label">{label ? label : RenderUtil.toLabel(name)}</label>
    )
}