import {Field, useForm, useFormState} from "react-final-form";
import {useEffect, useState} from "react";
import Modal from "../Modal";
import Manage from "../manage/Manage";
import Label from "./Label";
import {getProperty} from "dot-prop";

export default function ({
                             name,
                             label,
                             dataProvider,
                             columns,
                             renderLabel,
                             onChange,
                             showLabel = true,
                             attributeToId = v => v,
                             objectToId = v => v,
                             convertToAttribute = v => v,
                             showFreeSearch = false,
                             validate,
                             key
                         }) {
    const [display, setDisplay] = useState(false);
    const [obj, setObj] = useState();

    const form = useForm();

    const state = useFormState();

    const value = getProperty(state?.values, name);

    useEffect(() => {
        if (!value) {
            if (obj) {
                setObj(null);
                if (onChange) {
                    onChange(null);
                }
            }
        } else {
            dataProvider.id(attributeToId(value)).then(d => {
                setObj(d);
                if (onChange) {
                    onChange(d);
                }
            });
        }
    }, [value]);

    const update = function (v) {
        console.log("v: ", v);
        dataProvider.id(objectToId(v)).then(d => {
            console.log(d);
            const converted = convertToAttribute(d);
            form.change(name, converted);
            // setObj(d);
            setDisplay(false);
        });
    }

    return (
        <Field name={name} key={key} component="hidden" validate={validate}>
            {({meta}) => (
                <div className="mb-3">
                    <Label label={label} showLabel={showLabel} name={name}/>
                    <div className={"input-group"}>
                        <div
                            className={"form-control rounded-start" + (meta.error && meta.touched ? " is-invalid" : "")}
                            onClick={() => setDisplay(true)}>{obj && renderLabel(obj)}</div>

                        <a className="btn btn-primary" onClick={() => setDisplay(true)}>Choose</a>
                        {display &&
                            <Modal display={display} setDisplay={setDisplay} size="xl">
                                <Manage showFreeSearch={showFreeSearch} card={false} dataProvider={dataProvider} columns={columns} onClick={update}/>
                            </Modal>
                        }
                    </div>
                    {meta.error && meta.touched && <span className="d-block invalid-feedback">{meta.error}</span>}
                </div>
            )}
        </Field>
    )
}