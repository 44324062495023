import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import LoadingResult from "./LoadingResult";
import arrayMutators from "final-form-arrays";
import {Form} from 'react-final-form'
import DeleteButton from "./form/DeleteButton";
import RenderUtil from "./RenderUtil";
import ActionButton from "./form/ActionButton";
import {toast} from "react-hot-toast";

export default function ({id, dataProvider, render, readOnly = false, label, showLabel = false, initialValue = {}}) {
    const [obj, setObj] = useState(initialValue);
    const [loading, setLoading] = useState(true);
    const [className, setClassName] = useState("btn-primary");

    let navigate = useNavigate();

    const isCreate = id === "new";

    useEffect(() => {
        if (!isCreate) {
            dataProvider.id(id).then(obj => {
                setObj(obj);
                setLoading(false)
            });
        } else {
            setObj({});
            setLoading(false)
        }
    }, []);

    const save = function (values, form) {

        const create = !values.id;

        const result = create ? dataProvider.create(values) : dataProvider.save(values);

        result.then(data => {
            toast.success("Saved!");
            setClassName("btn-success");
            form.initialize(data);
            setObj(data);
        }).catch(() => {
            toast.error("Failed!");
            setClassName("btn-danger");
        })
    }

    if (loading) {
        return <LoadingResult border={false}/>
    }

    return (
        <Form initialValues={obj} onSubmit={(v, f) => save(v, f)} mutators={{...arrayMutators}}
              render={({form, values, handleSubmit, pristine, submitting}) => (
                  <form onSubmit={handleSubmit}>
                      {showLabel &&
                          <div className="mb-4">
                              <h5>{isCreate ? "Create" : "Edit"} {label ? label : RenderUtil.toLabel(dataProvider.getEntity())}</h5>
                          </div>
                      }
                      {render({form, values})}
                      {!readOnly &&
                          <div>
                                  <span className="me-2">
                                      {/*<SaveButton dataProvider={dataProvider}/>*/}
                                      <ActionButton className={className} disabled={pristine || submitting}
                                                    onClick={() => form.submit()}
                                                    label={!values.id ? "Create" : "Save"} loading={submitting}/>
                                  </span>
                              <span className="me-2">
                                      <DeleteButton dataProvider={dataProvider}/>
                                  </span>
                          </div>
                      }
                  </form>

              )}/>
    )
}